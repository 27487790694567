import React from "react";
import TextField from "@mui/material/TextField";
import Colors from "./Colors";

function TextfieldComponent({
  onChange,
  InputProps,
  sx,
  error,
  multiline,
  ...props
}) {
  return (
    <TextField
      variant="outlined"
      placeholder={props.placeholder}
      onChange={onChange}
      autoComplete="off"
      InputProps={InputProps}
      multiline={multiline}
      rows={multiline ? 4 : 1}
      sx={{
        height: { xs: "32px", md: "38px" },
        width: "100%",
        padding: 0,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid",
            borderColor: error ? Colors.Red : Colors.black_60,
            transition: "border-color 0.3s ease",
          },
          "&:hover fieldset": {
            borderColor: error ? Colors.Red : Colors.black_80,
          },
          "&.Mui-focused fieldset": {
            borderColor: error ? Colors.Red : Colors.black_80,
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "IBM Plex Sans",
            fontSize: { xs: "10px", md: "14px" },
            fontWeight: 400,
            color: Colors.black_70,
          },
          "& .MuiInputBase-input": {
            padding: { xs: "8px 12px", md: "10px 16px" },
            fontSize: { xs: "10px", md: "14px" },
            fontFamily: "IBM Plex Sans",
            fontWeight: 400,
            color: Colors.black_80,
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
}

export default TextfieldComponent;
