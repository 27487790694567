import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider from "../images/slider.jpg";
// import slider1 from '../Images/slider1.png';
// import slider2 from '../Images/slider2.png';
// import slider3 from '../Images/slider3.png';
// import slider4 from '../Images/slider4.png';
// import slider5 from '../Images/slider5.png';
// import mobslider1 from '../Images/mobslider1.png';
// import mobslider2 from '../Images/mobslider2.png';
// import mobslider3 from '../Images/mobslider3.png';
// import mobslider4 from '../Images/mobslider4.png';
// import mobslider5 from '../Images/mobslider5.png';
import { Box, Typography, Button } from "@mui/material";
import Colors from "./Colors";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Header from "./Header";
import TypographyComponent from "./TypographyComponent";
import { useMediaQuery } from "@mui/material";
import ButtonRed from "./ButtonRed";
import ButtonTransparent from "./ButtonTransparent";
import TypographySliderHeading from "./TypographySliderHeader";
import TypographySliderComponent from "./TypographySliderComponent";

const carouselOverlayStyles = `
    .carousel-item {
        position: relative;
        width: 100%;
        height: 92vh;
        overflow: hidden;
    }

    .carousel-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color:rgba(45, 45, 45, 0.48);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1;
    }

    @media (max-width: 960px) {
        .carousel-item {
            height: 480px;
        }
    }

    @media (max-width: 600px) {
        .carousel-item {
            height: 520px;
        }
    }

    .carousel-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slick-dots {
        position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        z-index: 2;
    }

    .slick-dots li {
        margin: 0 5px;
        display: inline-block;
    }

    .slick-dots li button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background-color: #132436A1;
        cursor: pointer;
        outline: 'none';
    }

    .slick-dots li.slick-active button {
        background-color: #DD223F;
    }

    .slick-dots li button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
    }
`;

const carouselData = [
  { deskimage: slider, mobimage: slider },
  { deskimage: slider, mobimage: slider },
  { deskimage: slider, mobimage: slider },
  { deskimage: slider, mobimage: slider },
  { deskimage: slider, mobimage: slider },
];

const HomeSlider = () => {
  const settings = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  const isDesktop = useMediaQuery("(min-width:768px)");

  return (
    <>
      {/* <style>{carouselOverlayStyles}</style> */}
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Header currentPage={"Home"} />
        </Box>
        <Slider {...settings}>
          {carouselData.map((slide, index) => (
            <div key={index} className="carousel-item">
              <img
                src={isDesktop ? slide.deskimage : slide.mobimage}
                alt={`Slide ${index + 1}`}
                className="carousel-image"
              />

              <Box
                className="carousel-overlay"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "92%",
                    maxWidth: "90%",
                    paddingTop: "3%",
                    paddingBottom: { xs: "25%", md: "4%" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: { xs: "0%", md: "50%" },
                      height: "100%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "100%", md: "50%" },
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      gap: "24px",
                    }}
                  >
                    <TypographySliderHeading
                      sx={{
                        textAlign: "left",
                        color: Colors.black_80,
                      }}
                    >
                      Making your ideas happen !{" "}
                    </TypographySliderHeading>
                    <TypographySliderComponent
                      sx={{
                        textAlign: "left",
                        color: Colors.black_80,
                      }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut incididunt ut ipsum dolor sit amet, consectetur
                      adipiscing elit. Sed do eiusmod
                    </TypographySliderComponent>
                    {/* <TypographyComponent sx={{
                                            fontSize: { xs: '10px', md: '16px' }, lineHeight: { xs: '16px', md: '24px' }, marginTop: '-16px',
                                            textAlign: 'left', color: Colors.white
                                        }}>Join us in turning your ideas into impactful results. Let’s help your brand flock to success, together.</TypographyComponent> */}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "24px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ButtonTransparent
                        sx={{
                          maxWidth: { xs: "92px", md: "196px" },
                          color: Colors.black,
                        }}
                      >
                        Get started
                      </ButtonTransparent>
                      <ButtonRed sx={{ maxWidth: { xs: "92px", md: "196px" } }}>
                        Our services
                      </ButtonRed>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          ))}
        </Slider>
      </Box>
    </>
  );
};

export default HomeSlider;
