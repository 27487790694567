import React from "react";
import Colors from "./Colors";
import { Box, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CopyrightIcon from "@mui/icons-material/Copyright";
import TypographyComponent from "../components/TypographyComponent";

const footerStyles = `
.site-footer {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 24px 0;
  
}

.footer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #313131;
  z-index: 1;
}

.footer-content {
  position: relative;
  z-index: 2;
}
`;

function Footer(props) {
  const { currentPage } = props;
  const menu2 = [
    { label: "Home", link: "/" },
    { label: "Products", link: "/products" },
    { label: "Services", link: "/services" },
    { label: "About us", link: "/about-us" },
    { label: "Contact Us", link: "/contact-us" },
  ];

  const menu3 = [
    { label: "Instagram", link: "https://www.instagram.com/" },
    { label: "Facebook", link: "https://www.facebook.com/" },
  ];

  const menu1 = [
    { label: "Website development", link: "/services?focus=web" },
    { label: "App development", link: "/services?focus=app" },
    { label: "UI-UX designing", link: "/services?focus=uiDesign" },
    { label: "UI-UX audit ", link: "/services?focus=uiAudit" },
    { label: "Digital marketing", link: "/services?focus=digital" },
    { label: "Automation", link: "/services?focus=automation" },
    { label: "QA-Testing", link: "/services?focus=qa" },
    { label: "Cyber security", link: "/services?focus=cyber" },
  ];

  return (
    <>
      <style>{footerStyles}</style>
      <footer className="site-footer">
        <div className="footer-overlay"></div>
        <div className="footer-content">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            {/* Services Menu */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: { md: "200px", xs: "100px" },
                alignItems: "flex-start",
              }}
            >
              <TypographyComponent
                sx={{
                  padding: "8px",
                  fontWeight: 400,
                  fontSize: { xs: "16px", sm: "18px" },
                  color: Colors.white,
                }}
              >
                Our services
              </TypographyComponent>
              {menu1.map((menu) => (
                <Button
                  disableRipple
                  disableFocusRipple
                  key={menu.label}
                  component={RouterLink}
                  to={menu.link}
                  sx={{
                    color: Colors.white,
                    textTransform: "none",
                    fontSize: { md: "12px", sm: "12px", xs: "10px" },
                    fontFamily: "IBM Plex Sans",
                    fontWeight: 400,
                    transition: "color 0.2s ease, transform 0.1s ease",
                    "&:hover": {
                      color: Colors.primary,
                      fontWeight: 600,
                      transform: "scale(1.19)",
                      // fontSize:'14px',
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Box>

            {/* Main Menu */}
            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                width: "fit-content",
                flexDirection: "column",
                minWidth: { md: "150px", xs: "100px" },
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {menu2.map((menu) => (
                <Button
                  disableRipple
                  disableFocusRipple
                  key={menu.label}
                  component={RouterLink}
                  to={menu.link}
                  sx={{
                    color:
                      currentPage === menu.label
                        ? Colors.primary
                        : Colors.white,
                    fontSize:
                      currentPage === menu.label
                        ? { md: "14px", xs: "10px" }
                        : { md: "12px", xs: "10px" },
                    fontWeight: currentPage === menu.label ? 600 : 400,
                    fontFamily: "IBM Plex Sans",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    lineHeight: "16px",
                    transition: "color 0.2s ease, transform 0.1s ease",
                    "&:hover": {
                      fontWeight: 600,
                      transform: "scale(1.19)",
                      color: Colors.primary,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Box>

            {/* Social Links */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" }, // Show on both xs and md, but positioned differently due to parent flexDirection
                flexDirection: "column",
                minWidth: "100px",
                alignItems: "flex-start",
                mt: { xs: 2, md: 0 }, // Adds top margin on xs screens to create spacing below Main Menu
              }}
            >
              {menu3.map((menu) => (
                <Button
                  disableRipple
                  disableFocusRipple
                  key={menu.label}
                  component={Link}
                  href={menu.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: Colors.white,
                    fontFamily: "IBM Plex Sans",
                    textTransform: "none",
                    fontWeight: 400,
                    fontSize: { md: "12px", sm: "12px", xs: "10px" },
                    transition: "color 0.2s ease, transform 0.1s ease",
                    "&:hover": {
                      fontWeight: 600,
                      transform: "scale(1.19)",
                      color: Colors.primary,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Box>

            {/* Parent Box to Control Layout */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: { xs: "column", md: "row" }, // Stack vertically on xs screens, horizontally on md and above
                alignItems: "flex-start",
              }}
            >
              {/* Main Menu */}
              <Box
                sx={{
                  display: "flex",
                  width: "fit-content",
                  flexDirection: "column",
                  minWidth: { md: "150px", xs: "100px" },
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {menu2.map((menu) => (
                  <Button
                    key={menu.label}
                    component={RouterLink}
                    to={menu.link}
                    sx={{
                      color:
                        currentPage === menu.label
                          ? Colors.primary
                          : Colors.white,
                      fontSize:
                        currentPage === menu.label
                          ? { md: "14px", xs: "10px" }
                          : { md: "12px", xs: "10px" },
                      fontWeight: currentPage === menu.label ? 600 : 400,
                      fontFamily: "IBM Plex Sans",
                      justifyContent: "flex-start",
                      textTransform: "none",
                      lineHeight: "16px",
                      transition: "color 0.2s ease, transform 0.1s ease",
                      "&:hover": {
                        fontWeight: 600,
                        transform: "scale(1.19)",
                        color: Colors.primary,
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    {menu.label}
                  </Button>
                ))}
              </Box>

              {/* Social Links */}
              <Box
                sx={{
                  display: { xs: "flex", md: "flex" }, // Show on both xs and md, but positioned differently due to parent flexDirection
                  flexDirection: "column",
                  minWidth: "100px",
                  alignItems: "flex-start",
                  mt: { xs: 2, md: 0 }, // Adds top margin on xs screens to create spacing below Main Menu
                }}
              >
                {menu3.map((menu) => (
                  <Button
                    key={menu.label}
                    component={Link}
                    href={menu.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: Colors.white,
                      fontFamily: "IBM Plex Sans",
                      textTransform: "none",
                      fontWeight: 400,
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      transition: "color 0.2s ease, transform 0.1s ease",
                      "&:hover": {
                        fontWeight: 600,
                        transform: "scale(1.19)",
                        color: Colors.primary,
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    {menu.label}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Copyright Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: "20px",
            }}
          >
            <CopyrightIcon
              sx={{ color: Colors.white, fontSize: { md: "12px", xs: "8px" } }}
            />
            <TypographyComponent
              sx={{
                fontSize: { md: "12px", xs: "8px" },
                fontWeight: 400,
                color: Colors.white,
              }}
            >
              2023-2025 all rights reserved by
            </TypographyComponent>
            <Link
              href="https://www.varsha-info.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: Colors.white,
                fontSize: { md: "12px", xs: "8px" },
                fontWeight: 400,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              www.varsha-info.com
            </Link>
          </Box>
        </div>
      </footer>
    </>
  );
}

export default Footer;
