import React, { useEffect, useRef, useState } from "react";
import { Grid2, Box, useMediaQuery } from "@mui/material";
import HomeSlider from "../components/HomeSlider";
import TypographyComponent from "../components/TypographyComponent";
// import Footer from '../Components/Footer'
import Colors from "../components/Colors";
import { useNavigate } from "react-router-dom";
import service from "../images/ServicesIcon.png";
import home1 from "../images/home1.jpg";
import home2 from "../images/home2.jpg";
import home3 from "../images/home3.jpg";
import homeicon1 from "../images/homeicon1.png";
import homeicon4 from "../images/homeicon4.png";
import homeicon2 from "../images/homeicon2.png";
import homeicon3 from "../images/homeicon3.png";
import homeicon5 from "../images/homeicon5.png";
import homeicon6 from "../images/homeicon6.png";
import homeicon7 from "../images/homeicon7.png";
import homeicon8 from "../images/homeicon8.png";
import homemob7 from "../images/homeiconmob7.png";

import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ButtonRed from "../components/ButtonRed";
import Footer from "../components/Footer";
// import Footer from '../components/Footer'
import ReviewSlider from "../components/ReviewSlider";
import TypographyHeading from "../components/TypographyHeader";

function Home() {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  const boxesRef = useRef([]);
  const [animationStates, setAnimationStates] = useState(Array(5).fill(false));
  const animationStyles = {
    animation1: {
      opacity: 1,
      transform: "translateY(0)", // Final position
      transition: "opacity 0.6s ease, transform 0.6s ease",
    },
    animation2: {
      opacity: 1,
      transform: "scale(1)",
      transition: "opacity 0.6s ease, transform 0.6s ease",
    },
    animation3: {
      opacity: 1,
      transform: "rotate(0deg)",
      transition: "opacity 0.6s ease, transform 0.6s ease",
    },
    default: {
      opacity: 0,
      transform: "translateY(20px)", // Example fallback
      transition: "opacity 0.6s ease, transform 0.6s ease",
    },
  };
  const animationMapping = {
    0: "animation1",
    1: "animation1", // Same animation as box 0
    2: "animation2",
    3: "animation3",
    4: "animation3", // Same animation as box 3
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setAnimationStates((prev) => {
              const updated = [...prev];
              updated[index] = true; // Set the corresponding animation state to true
              return updated;
            });
          } else {
            setAnimationStates((prev) => {
              const updated = [...prev];
              updated[index] = false; // Reset animation state if it leaves the viewport
              return updated;
            });
          }
        });
      },
      { threshold: 0.3 }, // Adjust the threshold as needed
    );

    boxesRef.current.forEach((box) => {
      if (box) observer.observe(box); // Observe each box
    });

    return () => {
      boxesRef.current.forEach((box) => {
        if (box) observer.unobserve(box); // Cleanup observer
      });
    };
  }, []);
  const handleBoxClick = (label) => {
    navigate(`/services?focus=${label}`);
  };

  return (
    <Grid2
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <>
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <HomeSlider />
          </Box>
          <Box
            key={index}
            ref={(el) => (boxesRef.current[index] = el)}
            sx={{
              ...(animationStates[index]
                ? animationStyles[animationMapping[index]]
                : animationStyles.default),
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: "92%",
              paddingTop: { xs: "4%", md: "2%" },
              paddingBottom: { xs: "4%", md: "2%" },
              gap: { xs: "24px", md: "38px" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                gap: { md: "5%", xs: "8px" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative", // Important for positioning the pseudo-elements
                  display: "flex",
                  width: { md: "50%", xs: "100%" },
                  paddingTop: { xs: "4%", md: "2%" },
                  paddingBottom: { xs: "4%", md: "2%" },
                  height: "94%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Box to display the image */}
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "92%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={home1}
                    alt="successImage"
                  />
                </Box>

                {/* Top-left partial border */}
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "25%", // 25% of the width for the top border on the left side
                    height: "2px", // Thin horizontal line for the top-left border
                    backgroundColor: "#c1c1c1",
                  }}
                />

                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "2px", // Thin vertical line for the left-top border
                    height: "25%", // 25% of the height for the left border at the top
                    backgroundColor: "#c1c1c1",
                  }}
                />

                {/* Bottom-right partial border */}
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "25%", // 25% of the width for the bottom border on the right side
                    height: "2px", // Thin horizontal line for the bottom-right border
                    backgroundColor: "#c1c1c1",
                  }}
                />

                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "2px", // Thin vertical line for the right-bottom border
                    height: "25%", // 25% of the height for the right border at the bottom
                    backgroundColor: "#c1c1c1",
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: "flex",
                  gap: { xs: "14px", md: "24px" },
                  flexDirection: "column",
                }}
              >
                {/* <ButtonPink sx={{
                            fontWeight: 400, fontSize: '12px', maxWidth: '94px', width: '100%', height: '24px',
                            color: '#585858', lineHeight: '16px'
                        }}>About us</ButtonPink> */}
                <Box
                  sx={{
                    width: "94px",
                    height: "24px",
                    backgroundColor: Colors.secondary,
                    border: `1px solid ${Colors.primary}`,
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    color: Colors.black,
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  About Us
                </Box>
                <TypographyHeading sx={{ color: Colors.black_80 }}>
                  What is VIS?
                </TypographyHeading>
                <TypographyComponent
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "32px",
                    color: "#1D1A1A",
                  }}
                >
                  VIS is as an initiative from few IT professionals who have
                  strong experience in application development & application
                  support from different business domain. This makes VIS to
                  excel in application development & production support. As IT
                  processes evolves, VIS keep its workforce to be updated in new
                  technologies so that it can meet customers expectation. Our
                  service being integrated with customers business operation so
                  that make customers more comfortable. In an organization, VIS
                  identifies major process can be streamlined / automated , this
                  make customers to tune their workforce to yield maximum
                  productivity.
                </TypographyComponent>
                <ButtonRed
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    maxWidth: "170px",
                    width: "100%",
                    height: "48px",
                    lineHeight: "16px",
                  }}
                >
                  Read more
                </ButtonRed>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                gap: { xs: "8px", md: "5%" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative", // Important for positioning the pseudo-elements
                  display: { xs: "flex", md: "none" },
                  width: { xs: "100%", md: "50%" },
                  paddingTop: { xs: "4%", md: "2%" },
                  paddingBottom: { xs: "4%", md: "2%" },
                  height: "94%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Box to display the image */}
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "92%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={home2}
                    alt="successImage"
                  />
                </Box>

                {/* Top-left partial border */}
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "25%", // 25% of the width for the top border on the left side
                    height: "2px", // Thin horizontal line for the top-left border
                    backgroundColor: "#c1c1c1",
                  }}
                />

                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "2px", // Thin vertical line for the left-top border
                    height: "25%", // 25% of the height for the left border at the top
                    backgroundColor: "#c1c1c1",
                  }}
                />

                {/* Bottom-right partial border */}
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "25%", // 25% of the width for the bottom border on the right side
                    height: "2px", // Thin horizontal line for the bottom-right border
                    backgroundColor: "#c1c1c1",
                  }}
                />

                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "2px", // Thin vertical line for the right-bottom border
                    height: "25%", // 25% of the height for the right border at the bottom
                    backgroundColor: "#c1c1c1",
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: "flex",
                  gap: { xs: "14px", md: "24px" },
                  flexDirection: "column",
                }}
              >
                {/* <ButtonPink sx={{
                            fontWeight: 400, fontSize: '12px', maxWidth: '94px', width: '100%', height: '24px',
                            color: '#585858', lineHeight: '16px'
                        }}>Our services</ButtonPink> */}
                <Box
                  sx={{
                    width: "94px",
                    height: "24px",
                    backgroundColor: Colors.secondary,
                    border: `1px solid ${Colors.primary}`,
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    color: Colors.black,
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  Our services
                </Box>
                <TypographyHeading sx={{ color: Colors.black_80 }}>
                  What we do
                </TypographyHeading>
                <TypographyComponent
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "32px",
                    color: "#1D1A1A",
                  }}
                >
                  VIS is as an initiative from few IT professionals who have
                  strong experience in application development & application
                  support from different business domain. This makes VIS to
                  excel in application development & production support. As IT
                  processes evolves, VIS keep its workforce to be updated in new
                  technologies so that it can meet customers expectation. Our
                  service being integrated with customers business operation so
                  that make customers more comfortable. In an organization, VIS
                  identifies major process can be streamlined / automated , this
                  make customers to tune their workforce to yield maximum
                  productivity.
                </TypographyComponent>
                <ButtonRed
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    maxWidth: "170px",
                    width: "100%",
                    height: "48px",
                    lineHeight: "16px",
                  }}
                >
                  Read more
                </ButtonRed>
              </Box>
              <Box
                sx={{
                  position: "relative", // Important for positioning the pseudo-elements
                  display: { md: "flex", xs: "none" },

                  width: { xs: "100%", md: "50%" },
                  paddingTop: "2%",
                  paddingBottom: "2%",
                  height: "94%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Box to display the image */}
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "92%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={home2}
                    alt="successImage"
                  />
                </Box>

                {/* Top-left partial border */}
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "25%", // 25% of the width for the top border on the left side
                    height: "2px", // Thin horizontal line for the top-left border
                    backgroundColor: "#c1c1c1",
                  }}
                />

                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "2px", // Thin vertical line for the left-top border
                    height: "25%", // 25% of the height for the left border at the top
                    backgroundColor: "#c1c1c1",
                  }}
                />

                {/* Bottom-right partial border */}
                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "25%", // 25% of the width for the bottom border on the right side
                    height: "2px", // Thin horizontal line for the bottom-right border
                    backgroundColor: "#c1c1c1",
                  }}
                />

                <Box
                  sx={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "2px", // Thin vertical line for the right-bottom border
                    height: "25%", // 25% of the height for the right border at the bottom
                    backgroundColor: "#c1c1c1",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "96%",
              maxWidth: "92%",
              paddingTop: "2%",
              paddingBottom: "2%",
              gap: "16px",
            }}
          >
            <TypographyHeading
              sx={{
                alignItems: "center",
                paddingBottom: "2%",
              }}
            >
              Our services
            </TypographyHeading>
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: { xs: "12px", md: "67px" },
              }}
            >
              {[
                {
                  label: "Website Development",
                  mobileImage: homemob7,
                  desktopImage: homeicon1,
                },
                {
                  label: "App Development",
                  mobileImage: homemob7,
                  desktopImage: homeicon2,
                },
                {
                  label: "Software Development",
                  mobileImage: homemob7,
                  desktopImage: homeicon3,
                },
                {
                  label: "UI-UX Design",
                  mobileImage: homemob7,
                  desktopImage: homeicon4,
                },
                {
                  label: "UI-UX Audit",
                  mobileImage: homemob7,
                  desktopImage: homeicon5,
                },
                {
                  label: "Digital Marketing",
                  mobileImage: homemob7,
                  desktopImage: homeicon6,
                },
                {
                  label: "Automation",
                  mobileImage: homemob7,
                  desktopImage: homeicon7,
                },
                {
                  label: "QA- Testing",
                  mobileImage: homemob7,
                  desktopImage: homeicon8,
                },
              ]
                .filter((item) => item.label)
                .map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => handleBoxClick(item.label)}
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Stack the image and text vertically
                      alignItems: "center",
                      justifyContent: "center",
                      width: { xs: "calc(50% - 8px)", md: "calc(25% - 67px)" },
                      height: { xs: "86px", md: "196px" },
                      // background: '#F1F1F1',
                      padding: "8px",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      borderRadius: "8px",
                      border: "1px solid #cbb8b8",
                      gap: { xs: "8px", md: "16px" },
                      //   '&:hover': {
                      //     background: Colors.Red,
                      //     color: Colors.white,
                      //     '& .TypographyComponent': {
                      //       color: Colors.white,
                      //     }
                      //   }
                    }}
                  >
                    {/* Add an image above the label */}
                    <img
                      src={isDesktop ? item.desktopImage : item.mobileImage} // Replace with the actual image path
                      alt={item.label}
                      style={{
                        width: { xs: "14px", md: "38px" },
                        height: { xs: "14px", md: "38px" },
                      }} // Adjust size and spacing
                    />

                    {/* Label below the image */}
                    <TypographyComponent
                      className="TypographyComponent"
                      sx={{
                        fontSize: { xs: "8px", md: "24px" },
                        maxWidth: "135px",
                        textAlign: "center",
                        fontWeight: 600,
                        lineHeight: { xs: "12px", md: "30px" },
                        color: Colors.black_80,
                      }}
                    >
                      {item.label}
                    </TypographyComponent>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              backgroundColor: Colors.secondary,
              // backgroundImage: `url(${home3})`,
              // backgroundSize: 'cover',
              // backgroundPosition: 'center',
              // backgroundRepeat: 'no-repeat',
              mt: "4%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "92%",
                height: "176px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TypographyComponent
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "14px", md: "40px" },
                    lineHeight: { xs: "16px", md: "52px" },
                    color: Colors.black,
                  }}
                >
                  Get a free quote today
                </TypographyComponent>
                <ButtonRed
                  sx={{
                    maxWidth: { xs: "124px", md: "196px" },
                    width: "100%",
                    height: { xs: "32px", md: "48px" },
                    borderRadius: "4px",
                    gap: { xs: "4px", md: "10px" },
                    padding: "6px 10px",
                  }}
                >
                  <SendOutlinedIcon
                    sx={{
                      width: { xs: "12px", md: "16px" },
                      height: { xs: "12px", md: "16px" },
                    }}
                  ></SendOutlinedIcon>
                  <TypographyComponent
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: "12px", md: "18px" },
                      lineHeight: { xs: "16px", md: "24px" },
                      color: Colors.white,
                    }}
                  >
                    Send a request
                  </TypographyComponent>{" "}
                </ButtonRed>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "92%",
              alignItems: "center",
              justifyContent: "center",
              gap: "24px",
              flexDirection: "column",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "16px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TypographyHeading>Why choose VIS ?</TypographyHeading>
              <TypographyComponent>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla
              </TypographyComponent>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "16px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TypographyHeading>VIS focus on following:</TypographyHeading>
              <Box sx={{ width: "100%" }}>
                <ul>
                  <li>
                    <TypographyComponent>
                      Designing of data warehouse - Understanding vital fact
                      elements with surrounding deciding attributes (dimensions)
                      as conceptual schema. Converting this conceptual schema to
                      effective seamless & reliable RDBMS physical model.
                    </TypographyComponent>
                  </li>
                  <li>
                    <TypographyComponent>
                      Designing Data Integration Process (ETL) from different
                      source systems & implementation – This involves ETL
                      operations of duplicate removal, reference checking,
                      merging, change capture operation etc.
                    </TypographyComponent>
                  </li>
                  <li>
                    <TypographyComponent>
                      Data Reporting – Understanding what business requires,
                      implementing reports by pulling data from data repository.
                    </TypographyComponent>
                  </li>
                  <li>
                    <TypographyComponent>
                      Data Analysis & trend analysis – With the help of data
                      science tools, discover underlying patterns in historical
                      data and effectively communicate them to decision/policy
                      makers.
                    </TypographyComponent>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 24px)",
              maxWidth: "92%",
              height: "100%",
              justifyContent: "left",
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "12px",
            }}
          >
            <TypographyHeading
              sx={{
                textAlign: "center",
              }}
            >
              Clients review
            </TypographyHeading>
            <TypographyComponent>
              At Helping Flocks, we believe that our success is measured by the
              success of our clients. Hear from some of the incredible brands
              we've had the pleasure of working with. Their feedback is a
              testament to our dedication, creativity, and results-driven
              approach.
            </TypographyComponent>
            <ReviewSlider></ReviewSlider>
          </Box>
        </>
      ))}
      <Footer currentPage={"Home"}></Footer>
    </Grid2>
  );
}

export default Home;
