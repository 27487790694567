import React from "react";
import Typography from "@mui/material/Typography";
import Colors from "./Colors";

function TypographyComponent({ onClick, sx, ...props }) {
  return (
    <Typography
      sx={{
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        fontSize: { xs: "12px", sm: "12px", md: "16px" },
        color: sx?.color || Colors.black, // Use the color from sx if provided, otherwise default to black
        lineHeight: { xs: "24px", sm: "24px", md: "32px" },
        fontStyle: "normal",
        ...sx, // Spread the rest of sx to allow further customization
      }}
      {...props}
      onClick={onClick}
    ></Typography>
  );
}

export default TypographyComponent;
