import React from "react";
import { Avatar, Box } from "@mui/material";
import Colors from "./Colors";

function BalloonAvatar({ src, alt }) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        width: { xs: "48px", md: "170px" }, // Adjust the width as needed
        height: { xs: "50px", md: "174px" }, // Adjust the height as needed
        borderRadius: "100% 100% 0 100%", // Rounded corner only at the bottom right
        backgroundColor: Colors.primary, // Balloon color
        overflow: "hidden",
        boxShadow: 2,
      }}
    >
      <Avatar
        src={src}
        alt={alt}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "0%", // Keep avatar circular
          position: "absolute",
          top: "0",
          left: "0",
        }}
      />
    </Box>
  );
}

export default BalloonAvatar;
