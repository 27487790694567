import React from "react";
import Typography from "@mui/material/Typography";
import Colors from "./Colors";

function TypographyHeading({ onClick, sx, ...props }) {
  return (
    <Typography
      sx={{
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        fontSize: { xs: "18px", sm: "18px", md: "32px" },
        color: Colors.black,
        lineHeight: { xs: "14px", sm: "14px", md: "28px" },
        fontStyle: "normal",
        ...sx,
      }}
      {...props}
      onClick={onClick}
    ></Typography>
  );
}

export default TypographyHeading;
