import React, { useEffect, useRef, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import TypographyComponent from "../components/TypographyComponent";
import { useMediaQuery } from "@mui/material";
import TypographyHeading from "../components/TypographyHeader";
import { useInView } from "react-intersection-observer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    // Trigger animation only on initial load
    setHasAnimated(true);
  }, []); // Empty dependency array means this runs once when the component mounts
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing once in view
        }
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const focusLabel = params.get("focus");
    if (focusLabel) {
      const element = document.getElementById(focusLabel);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Grid2
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          animation: hasAnimated ? "slideDown 1s ease-out forwards" : "none",
          "@keyframes slideDown": {
            "0%": { transform: "translateY(-100%)" },
            "100%": { transform: "translateY(0)" },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <Header
          currentPage={"About us"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 2,
          }}
        />
      </Box>

      <Box
        ref={ref}
        sx={{
          height: "95%",
          display: "flex",
          width: "100%",
          maxWidth: "90%",
          gap: { sm: "14px", md: "28px", xs: "14px" },
          paddingTop: { sm: "2%", xs: "2%", md: "3%" },
          paddingBottom: { xs: "16px", md: "68px" },
          flexDirection: "column",
          alignItems: "center",
          opacity: isInView ? 1 : 0, // Fade in when in view
          transform: isInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
          transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
        }}
      >
        <TypographyHeading
          sx={{
            fontSize: { xs: "20px", md: "42px" },
            lineHeight: { md: "84px", xs: "40px" },
          }}
        >
          About us
        </TypographyHeading>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: "12px", md: "24px", xs: "12px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { md: "100%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "24px" },
              position: "relative",
              "&::before, &::after": {
                content: '""',
                position: "absolute",
              },
              "&::before": {
                top: 0,
                left: 0,
                width: "158px",
                height: "158px",
                borderTop: "0.5px solid #D40033",
                borderLeft: "0.5px solid #D40033",
                boxSizing: "border-box",
              },
              "&::after": {
                bottom: 0,
                right: 0,
                width: "158px",
                height: "158px",
                borderBottom: "0.5px solid #D40033",
                borderRight: "0.5px solid #D40033",
                boxSizing: "border-box",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "94%", md: "94%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </TypographyComponent>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer currentPage={"About us"} />
    </Grid2>
  );
};

export default About;
