import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import "../components/animations.css"; // Adjust the path as necessary

const Test = () => {
  const boxesRef = useRef([]); // Ref to hold the box elements
  const [animationStates, setAnimationStates] = useState(Array(3).fill(false)); // State for animation visibility

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setAnimationStates((prev) => {
              const updated = [...prev];
              updated[index] = true; // Set the corresponding animation state to true
              return updated;
            });
          }
        });
      },
      { threshold: 0.3 }, // Adjust threshold as needed
    );

    boxesRef.current.forEach((box) => {
      if (box) observer.observe(box); // Observe each box
    });

    return () => {
      boxesRef.current.forEach((box) => {
        if (box) observer.unobserve(box); // Cleanup observer
      });
    };
  }, []);

  // Define different animations for each box
  const animations = ["slide-in-right", "slide-in-left", "slide-in-top"];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      {Array.from({ length: 3 }).map((_, index) => (
        <Box
          key={index}
          ref={(el) => (boxesRef.current[index] = el)}
          className={animationStates[index] ? animations[index] : "hidden"} // Apply animation class
          sx={{
            height: "100px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightblue",
            display: "flex",
          }}
        >
          <Typography variant="h6">Box {index + 1}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Test;
