import React, { useEffect, useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import Colors from "./Colors";
import { Avatar } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ButtonRed from "./ButtonRed";

const pages = ["Home", "Services", "Products", "About us", "Contact us"];

function Header(props) {
  const { currentPage } = props;
  console.log("header", props);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleMenuItemClick = (page) => {
    setSelectedPage(page);
    handleCloseDrawer();
  };

  return (
    <AppBar
      position="static"
      sx={{
        height: { xs: "68px", md: "74px" },
        justifyContent: "center",
        width: "100%",
        boxShadow: "none",
        background: "transparent",
        alignItems: "center",
        borderBottom: "1px solid #E0BFBF",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          height: { xs: "62px", md: "84px" },
          width: { xs: "88%", md: "91%" },
        }}
      >
        <Toolbar disableGutters sx={{ height: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: { xs: "92px", sm: "170px", md: "210px" },
                height: { xs: "36px", sm: "36px", md: "36px" },
                display: { xs: "none", sm: "flex", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                background: "#7d7d7d",
              }}
            >
              {/* <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} /> */}
            </Box>
            <Box
              sx={{
                width: { xs: "110px", md: "24px" },
                height: { xs: "32px", md: "24px" },
                display: { xs: "flex", sm: "none", md: "none" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", sm: "none", md: "none" },
                width: "30%",
              }}
            >
              <IconButton
                size="large"
                aria-label="open drawer"
                onClick={handleOpenDrawer}
                sx={{ color: Colors.black }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={openDrawer}
                onClose={handleCloseDrawer}
                sx={{
                  "& .MuiDrawer-paper": {
                    backgroundColor: Colors.white,
                  },
                  backdropFilter: "none",
                }}
                BackdropProps={{
                  sx: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    aria-label="close drawer"
                    onClick={handleCloseDrawer}
                    sx={{ ml: "auto", color: Colors.black }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box sx={{ width: 200 }}>
                  {pages.map((page) => {
                    return (
                      <Link
                        key={page}
                        to={`/${page.toLowerCase().replace(/\s+/g, "-")}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <MenuItem
                          selected={selectedPage === page}
                          onClick={() => handleMenuItemClick(page)}
                          sx={{
                            color:
                              selectedPage === page
                                ? Colors.primary
                                : Colors.black_80,
                            display: "block",
                            textTransform: "none",
                            background:
                              currentPage === page ? Colors.red : Colors.white,
                            fontFamily: "IBM Plex Sans",
                            fontSize: "16px",
                            lineHeight: "36px",
                            fontWeight: currentPage === page ? 600 : 400,
                            "&:hover": {
                              color: "white",
                              bgcolor: "white",
                              fontWeight: "600",
                            },
                          }}
                        >
                          {page}
                        </MenuItem>
                      </Link>
                    );
                  })}
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", md: "flex" },
                height: "100%",
                width: "60%",
                justifyContent: "right",
                alignItems: "center",
                gap: "56px",
              }}
            >
              {pages.map((page) => {
                return (
                  <Button
                    key={page}
                    sx={{
                      color:
                        selectedPage === page
                          ? Colors.primary
                          : Colors.black_80,
                      display: "block",
                      textTransform: "none",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "16px",
                      lineHeight: "26px",
                      fontWeight: 700,
                      "&:hover": {
                        color: Colors.primary,
                        bgcolor: "none",
                        textDecoration: "none",
                        // textUnderlineOffset: '8px', textDecorationColor: Colors.Red,
                        background: "none",
                      },
                    }}
                  >
                    <Link
                      to={`/${page.toLowerCase().replace(/\s+/g, "-")}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {page}
                    </Link>
                  </Button>
                );
              })}
            </Box>
            {/* <Box sx={{width:{ xs: '70%', md: '156px' }  , height: { xs: '36px', md: '40px' }, display: 'flex', justifyContent: 'right', 
                alignItems: 'center', }}>

               <ButtonRed  sx={{width:{ xs:'fit-content !important', md: '100% !important' }}}>Get in touch</ButtonRed> 
                </Box> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
