import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Grid2,
} from "@mui/material";
import Header from "../components/Header";
// import headerbg from '../Images/headerbg.png';
// import bgmob from '../Images/bgmob.jpg';
import Colors from "../components/Colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Footer from "../components/Footer";
import s1 from "../images/s1.png";
import s2 from "../images/s2.png";
import s3 from "../images/s3.png";
import s4 from "../images/s4.png";
import s5 from "../images/s5.png";
import s6 from "../images/s6.png";
import s7 from "../images/s7.png";
import s8 from "../images/s8.png";
import cyber from "../images/cyber.png";
import s1mob from "../images/s1mob.png";
import s2mob from "../images/s2mob.png";
import s3mob from "../images/s3mob.png";
import s4mob from "../images/s4mob.png";
import s5mob from "../images/s5mob.png";
import s6mob from "../images/s6mob.png";
import s7mob from "../images/s7mob.png";
import s8mob from "../images/s8mob.png";
import s9mob from "../images/s9mob.png";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TypographyComponent from "../components/TypographyComponent";
import { useMediaQuery } from "@mui/material";
import TypographyHeading from "../components/TypographyHeader";
import { useInView } from "react-intersection-observer";
import SvgComponent from "../components/SvgComponent";

const Services = () => {
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [webIsInView, setWebIsInView] = useState(false);
  const [appIsInView, setAppIsInView] = useState(false);
  const [softwareIsInView, setSoftwareIsInView] = useState(false);
  const [uiDesignIsInView, setUiDesignIsInView] = useState(false);
  const [uiAuditIsInView, setUiAuditIsInView] = useState(false);
  const [digitalIsInView, setDigitalIsInView] = useState(false);
  const [automationIsInView, setAutomationIsInView] = useState(false);
  const [qaIsInView, setQaIsInView] = useState(false);
  const [cyberIsInView, setCyberIsInView] = useState(false);
  const webRef = useRef(null);
  const appRef = useRef(null);
  const softwareRef = useRef(null);
  const uiDesignRef = useRef(null);
  const uiAuditRef = useRef(null);
  const digitalRef = useRef(null);
  const automationRef = useRef(null);
  const qaRef = useRef(null);
  const cyberRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === webRef.current) {
              setWebIsInView(true);
            } else if (entry.target === appRef.current) {
              setAppIsInView(true);
            } else if (entry.target === softwareRef.current) {
              setSoftwareIsInView(true);
            } else if (entry.target === uiDesignRef.current) {
              setUiDesignIsInView(true);
            } else if (entry.target === uiAuditRef.current) {
              setUiAuditIsInView(true);
            } else if (entry.target === digitalRef.current) {
              setDigitalIsInView(true);
            } else if (entry.target === automationRef.current) {
              setAutomationIsInView(true);
            } else if (entry.target === qaRef.current) {
              setQaIsInView(true);
            } else if (entry.target === cyberRef.current) {
              setCyberIsInView(true);
            }
            observer.unobserve(entry.target); // Stop observing once in view
          }
        });
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    // Add observers for each reference
    if (webRef.current) observer.observe(webRef.current);
    if (appRef.current) observer.observe(appRef.current);
    if (softwareRef.current) observer.observe(softwareRef.current);
    if (uiDesignRef.current) observer.observe(uiDesignRef.current);
    if (uiAuditRef.current) observer.observe(uiAuditRef.current);
    if (digitalRef.current) observer.observe(digitalRef.current);
    if (automationRef.current) observer.observe(automationRef.current);
    if (qaRef.current) observer.observe(qaRef.current);
    if (cyberRef.current) observer.observe(cyberRef.current);

    // Clean up function to unobserve each reference
    return () => {
      if (webRef.current) observer.unobserve(webRef.current);
      if (appRef.current) observer.unobserve(appRef.current);
      if (softwareRef.current) observer.unobserve(softwareRef.current);
      if (uiDesignRef.current) observer.unobserve(uiDesignRef.current);
      if (uiAuditRef.current) observer.unobserve(uiAuditRef.current);
      if (digitalRef.current) observer.unobserve(digitalRef.current);
      if (automationRef.current) observer.unobserve(automationRef.current);
      if (qaRef.current) observer.unobserve(qaRef.current);
      if (cyberRef.current) observer.unobserve(cyberRef.current);
    };
  }, []);

  // useEffect(() => {
  //     const params = new URLSearchParams(location.search);
  //     const focusLabel = params.get('focus');

  //     if (focusLabel) {
  //         setTimeout(() => {
  //             const element = document.getElementById(focusLabel);
  //             if (element) {
  //                 element.scrollIntoView({
  //                     behavior: 'smooth',
  //                     block: 'start',
  //                     inline: 'nearest'
  //                 });
  //             }
  //         }, 100);
  //     }
  // }, [location.search]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const focusLabel = params.get("focus");

    if (focusLabel) {
      // Focus on the element if 'focus' parameter is present
      setTimeout(() => {
        const element = document.getElementById(focusLabel);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }, 100);
    } else {
      // Scroll to top if no 'focus' parameter
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location.search]);

  return (
    <Grid2
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          animation: "slideDown 1s ease-out forwards",
          "@keyframes slideDown": {
            "0%": { transform: "translateY(-100%)" }, // Start off-screen (above)
            "100%": { transform: "translateY(0)" }, // End at its normal position
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <Header
          currentPage={"Services"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 2,
          }}
        />
      </Box>

      <Box
        sx={{
          height: "95%",
          display: "flex",
          width: "100%",
          maxWidth: "90%",
          gap: { sm: "14px", md: "28px", xs: "14px" },
          paddingTop: { sm: "2%", xs: "2%", md: "3%" },
          paddingBottom: { xs: "1%", md: "4%" },
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          id="web"
          ref={webRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            opacity: webIsInView ? 1 : 0, // Fade in when in view
            transform: webIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          }}
        >
          <TypographyHeading
            sx={{
              fontSize: { xs: "20px", md: "42px" },
              lineHeight: { md: "84px", xs: "40px" },
              mb: "24px",
            }}
          >
            Our services
          </TypographyHeading>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { sm: "12px", md: "24px", xs: "12px" },
            }}
          >
            <TypographyHeading>Web development</TypographyHeading>
            <Box
              id="web"
              sx={{
                display: "flex",
                width: { md: "100%" },
                height: "98%",
                flexDirection: { md: "row", xs: "column" },
                gap: { md: "42px", xs: "0px" },
                position: "relative",
                "@media (min-width: 900px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "158px", // Adjust length as needed
                    height: "158px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "158px", // Adjust length as needed
                    height: "158px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <Box
                sx={{
                  width: { xs: "97%", md: "50%" },
                  height: "100%",
                  padding: "3%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "&::before, &::after": {
                      content: '""',
                      position: "absolute",
                    },
                    // Top-left border
                    "&::before": {
                      top: 0,
                      left: 0,
                      width: "92px", // Adjust length as needed
                      height: "82px",
                      borderTop: "0.5px solid #D40033",
                      borderLeft: "0.5px solid #D40033",
                      boxSizing: "border-box",
                    },
                    // Bottom-right border
                    "&::after": {
                      bottom: 0,
                      right: 0,
                      width: "92px", // Adjust length as needed
                      height: "82px",
                      borderBottom: "0.5px solid #D40033",
                      borderRight: "0.5px solid #D40033",
                      boxSizing: "border-box",
                    },
                  },
                }}
              >
                <SvgComponent
                  desktopSrc={s1}
                  mobileSrc={s1mob}
                  alt="Image 1"
                  style={{
                    width: "100%",
                    maxWidth: { xs: "97%", md: "100%" },
                    height: "100%",
                  }}
                />
                {/* <Box sx={{display:'flex',
                                    width:'100%',maxWidth: { xs: '97%', md: '100%' },height:'100%',
                                    backgroundImage: `url(${s1})`, backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100% 100%',border:'1px solid'}}>                                     
                                </Box> */}
                {/* <img style={{ width: '100%',
                                 maxWidth: { xs: '97%', md: '100%' }, 
                                 height: '100%' }}
                                    src={isDesktop ? s1 : s1mob} alt="successImage" /> */}
              </Box>
              <Box
                ref={webRef}
                sx={{
                  width: { xs: "97%", md: "44%" },
                  padding: "3%",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: "flex",
                  gap: { xs: "14px", md: "24px" },
                  flexDirection: "column",
                }}
              >
                <TypographyComponent>
                  Web development is the process of building and maintaining
                  websites that are dynamic, user-friendly, and tailored to meet
                  specific business needs. It encompasses both the design and
                  functionality of a site, ensuring that it is visually
                  appealing while providing a smooth and efficient user
                  experience. From creating responsive layouts that adapt to any
                  device to implementing the latest technologies for faster load
                  times and security, web development brings your vision to
                  life. Whether you need a simple landing page or a complex
                  e-commerce platform, professional web development ensures your
                  website performs optimally and aligns with your brand's goals.
                </TypographyComponent>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          id="app"
          ref={appRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: appIsInView ? 1 : 0, // Fade in when in view
            transform: appIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>App development</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "100%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 1 },
                width: { xs: "97%", md: "44%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
                // animation: 'slideInLeft 1s ease-out',  // Slide from the left
                // '@keyframes slideInLeft': {
                //     '0%': { transform: 'translateX(-100%)', opacity: 0 }, // Start off-screen to the left
                //     '100%': { transform: 'translateX(0)', opacity: 1 },  // End in normal position
                // }
              }}
            >
              <TypographyComponent>
                App development involves the creation of mobile and web
                applications that provide seamless user experiences across
                various platforms. By combining innovative design with
                cutting-edge technology, app development enables businesses to
                reach and engage their audience more effectively. Whether it's a
                mobile app for iOS or Android or a web-based solution, app
                development focuses on functionality, performance, and user
                interface to deliver a product that meets specific needs. From
                concept to deployment, professional app development ensures that
                your app is not only visually appealing but also highly
                responsive, secure, and scalable, driving business growth in the
                digital age.
              </TypographyComponent>
            </Box>
            <Box
              sx={{
                order: { xs: 1, md: 2 },
                width: { xs: "97%", md: "50%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
                // animation: 'slideInRight 1s ease-out', // Slide from the right
                // '@keyframes slideInRight': {
                //     '0%': { transform: 'translateX(100%)', opacity: 0 },  // Start off-screen to the right
                //     '100%': { transform: 'translateX(0)', opacity: 1 },   // End in normal position
                // }
              }}
            >
              <SvgComponent
                desktopSrc={s2}
                mobileSrc={s2mob}
                alt="Image 2"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
              {/* <img style={{ width: '100%', maxWidth: { xs: '97%', md: '100%' },
                             height: '100%' }}
                                src={isDesktop ? s2 : s2mob} alt="successImage" /> */}
            </Box>
          </Box>
        </Box>
        <Box
          id="software"
          ref={softwareRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: softwareIsInView ? 1 : 0, // Fade in when in view
            transform: softwareIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>Software development</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "97%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "3%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
                // animation: 'slideInLeft 1s ease-out',  // Slide from the left
                // '@keyframes slideInLeft': {
                //     '0%': { transform: 'translateX(-100%)', opacity: 0 }, // Start off-screen to the left
                //     '100%': { transform: 'translateX(0)', opacity: 1 },  // End in normal position
                // }
              }}
            >
              <SvgComponent
                desktopSrc={s3}
                mobileSrc={s3mob}
                alt="Image 3"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
                // animation: 'slideInRight 1s ease-out', // Slide from the right
                // '@keyframes slideInRight': {
                //     '0%': { transform: 'translateX(100%)', opacity: 0 },  // Start off-screen to the right
                //     '100%': { transform: 'translateX(0)', opacity: 1 },   // End in normal position
                // }
              }}
            >
              <TypographyComponent>
                Software development is the process of designing, coding,
                testing, and maintaining software solutions that meet specific
                business or user needs. It involves creating custom
                applications, systems, and programs that improve efficiency,
                automate processes, and solve complex problems. Whether
                developing desktop applications, enterprise software, or
                cloud-based solutions, software development focuses on
                delivering secure, scalable, and high-performing products. By
                utilizing modern development frameworks and methodologies,
                professional software development ensures that businesses can
                operate smoothly, adapt to changing demands, and stay
                competitive in the ever-evolving digital landscape.
              </TypographyComponent>
            </Box>
          </Box>
        </Box>
        <Box
          id="uiDesign"
          ref={uiDesignRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: uiDesignIsInView ? 1 : 0, // Fade in when in view
            transform: uiDesignIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>UI-UX Design</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 1 },
                width: { xs: "97%", md: "44%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                UI / UX design is essential for creating digital experiences
                that are not only visually appealing but also intuitive and
                user-friendly. UI (User Interface) design focuses on the layout
                and aesthetic elements of a website or app, ensuring that each
                interaction is visually engaging and aligns with the brand’s
                identity. UX (User Experience) design, on the other hand, is
                about enhancing the overall usability, ensuring users can
                navigate the platform seamlessly and achieve their goals
                effortlessly. A well-executed UI/UX design creates a smooth,
                enjoyable experience for users, improving engagement and
                satisfaction, which in turn drives business success.
              </TypographyComponent>
            </Box>
            <Box
              sx={{
                order: { xs: 1, md: 2 },
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={s4}
                mobileSrc={s4mob}
                alt="Image 4"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          id="uiAudit"
          ref={uiAuditRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: uiAuditIsInView ? 1 : 0, // Fade in when in view
            transform: uiAuditIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>UI-UX Audit</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={s5}
                mobileSrc={s5mob}
                alt="Image 5"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                A UI/UX audit is a comprehensive evaluation of a website or
                app's design and functionality, aimed at identifying areas that
                can be improved to enhance user experience. During the audit,
                usability experts analyze key elements such as navigation,
                layout, responsiveness, and overall user flow to uncover pain
                points that may be hindering user engagement or conversions. By
                assessing both the visual design (UI) and how users interact
                with the platform (UX), a UI/UX audit provides actionable
                insights to refine the interface, streamline processes, and
                ensure a smoother, more enjoyable experience. This process is
                essential for optimizing performance and achieving better
                business outcomes.
              </TypographyComponent>
            </Box>
          </Box>
        </Box>
        <Box
          id="digital"
          ref={digitalRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: digitalIsInView ? 1 : 0, // Fade in when in view
            transform: digitalIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>Digital marketing</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 1 },
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                Digital marketing is an essential component for driving online
                success and growth in today’s competitive landscape. By
                leveraging strategies like search engine optimization (SEO),
                pay-per-click (PPC) advertising, social media marketing, and
                content creation, digital marketing helps businesses increase
                their visibility, engage with their audience, and convert leads
                into loyal customers. It integrates data-driven insights with
                creative solutions to tailor campaigns that resonate with target
                audiences and deliver measurable results. Whether through email
                marketing, influencer partnerships, or performance-based ads,
                digital marketing allows brands to build meaningful connections,
                optimize online presence, and achieve sustainable growth across
                all digital platforms.
              </TypographyComponent>
            </Box>
            <Box
              sx={{
                order: { xs: 1, md: 2 },
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={s6}
                mobileSrc={s6mob}
                alt="Image 6"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          id="automation"
          ref={automationRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: automationIsInView ? 1 : 0, // Fade in when in view
            transform: automationIsInView
              ? "translateY(0)"
              : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>Automation</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={s7}
                mobileSrc={s7mob}
                alt="Image 7"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                Automation is transforming the way businesses operate by
                streamlining processes, reducing manual tasks, and increasing
                efficiency. In web development and beyond, automation allows for
                the integration of advanced tools and technologies to manage
                workflows, perform repetitive tasks, and enhance accuracy.
                Whether it's automating customer interactions through chatbots,
                managing email marketing campaigns, or streamlining software
                updates, automation enables businesses to save time and
                resources while maintaining high levels of productivity. By
                implementing automation solutions, companies can focus on
                innovation and growth, ensuring they stay competitive in an
                ever-evolving digital landscape.
              </TypographyComponent>
            </Box>
          </Box>
        </Box>
        <Box
          id="qa"
          ref={qaRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: qaIsInView ? 1 : 0, // Fade in when in view
            transform: qaIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>QA- Testing</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 1 },
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                QA (Quality Assurance) testing is a vital step in the web
                development process, ensuring that websites and applications
                function flawlessly before they are launched. Through thorough
                testing, QA professionals identify bugs, usability issues, and
                performance problems that could impact the user experience. By
                evaluating everything from load times to security
                vulnerabilities, QA testing guarantees that the final product
                meets high standards of quality, reliability, and efficiency.
                This process not only helps prevent costly errors post-launch
                but also ensures that the website or app delivers a seamless and
                satisfying experience to users across different devices and
                platforms.
              </TypographyComponent>
            </Box>
            <Box
              sx={{
                order: { xs: 1, md: 2 },
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={s8}
                mobileSrc={s8mob}
                alt="Image 8"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          id="cyber"
          ref={cyberRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            opacity: cyberIsInView ? 1 : 0, // Fade in when in view
            transform: cyberIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
            gap: { sm: "12px", md: "24px", xs: "16px" },
          }}
        >
          <TypographyHeading>Cyber Security</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={cyber}
                mobileSrc={s9mob}
                alt="Image 9"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                Cybersecurity is the practice of protecting systems, networks,
                and data from digital attacks, unauthorized access, and damage.
                It encompasses a range of measures to safeguard sensitive
                information, including the use of firewalls, encryption,
                multi-factor authentication, and regular software updates. With
                the increasing reliance on technology, cybersecurity is
                essential to prevent data breaches, ensure user privacy, and
                maintain the integrity of systems across both personal and
                organizational levels. This field continually evolves to address
                new and sophisticated threats, making it critical for
                individuals and businesses to stay vigilant and proactive
              </TypographyComponent>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer currentPage={"Services"}></Footer>
    </Grid2>
  );
};

export default Services;
