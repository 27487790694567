import React, { useRef, useState } from "react";
import { Box, Button, Grid2, IconButton, TextField } from "@mui/material";
import Header from "../components/Header";
import Colors from "../components/Colors";
import Footer from "../components/Footer";
import home1 from "../images/home1.jpg";
import home2 from "../images/home2.jpg";
import home3 from "../images/whyVIS.svg";
import home4 from "../images/VISfocus.svg";
import homeicon1 from "../images/homeicon1.svg";
import homeicon4 from "../images/homeicon4.svg";
import homeicon2 from "../images/homeicon2.svg";
import homeicon3 from "../images/homeicon3.svg";
import homeicon5 from "../images/homeicon5.svg";
import homeicon6 from "../images/homeicon6.svg";
import homeicon7 from "../images/homeicon7.svg";
import homeicon8 from "../images/homeicon8.svg";
import homeicon9 from "../images/homeicon9.svg";
import homeicon1mob from "../images/homeicon1mob.svg";
// import homeicon4mob from '../images/homeicon4mob.svg'
import homeicon2mob from "../images/homeicon2mob.svg";
import homeicon3mob from "../images/homeicon3mob.svg";
import homeicon5mob from "../images/homeicon5mob.svg";
import homeicon6mob from "../images/homeicon6mob.svg";
import homeicon7mob from "../images/homeicon7mob.svg";
import homeicon8mob from "../images/homeicon8mob.svg";
import homeicon9mob from "../images/homeicon9mob.svg";
import vis1 from "../images/visbg.png";
import vis2 from "../images/vis2.svg";
import slider from "../images/slider.jpg";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import TypographyComponent from "../components/TypographyComponent";
import { useMediaQuery } from "@mui/material";
import TypographyHeading from "../components/TypographyHeader";
import ButtonRed from "./ButtonRed";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import ReviewSlider from "./ReviewSlider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import emailjs from "emailjs-com";
import TextfieldComponent from "../components/TextfieldComponent";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SliderContent from "./SliderContent";
import WhatIsVISComponent from "./WhatIsVis";
import WhatWeDo from "./WhatWeDo";
import QuoteBox from "./GetQuote";
import WhyChooseVis from "./WhyChooseVis";
import WhatIsVis from "./WhatIsVis";
import VisFocus from "./VisFocus";

const HomeNew = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const animationTriggered = useRef({ left: false, right: false });
  const whyVisRef = useRef(null);
  const [animationWhyVis, setAnimationWhyVis] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !animationTriggered) {
          setAnimationWhyVis(true);
          observer.disconnect(); // Stop observing after animation triggers
        }
      },
      { threshold: 0.1 },
    );

    if (whyVisRef.current) {
      observer.observe(whyVisRef.current);
    }

    return () => {
      if (whyVisRef.current) {
        observer.unobserve(whyVisRef.current);
      }
    };
  }, [animationTriggered]);

  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:768px)");
  const boxesRef = useRef([]);
  const boxRef = useRef([]);
  const [animate, setAnimate] = useState(false);
  const [showContentQuote, setShowContentQuote] = useState(false);
  const [shake, setShake] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setAnimate(true);
          setShake(true);

          // Reset shake after animation duration (e.g., 0.5s)
          setTimeout(() => setShake(false), 500);
        }
      },
      { threshold: 0.1 }, // Adjust the threshold as needed
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (animate) {
      // Show content after the animation has started
      setShowContentQuote(true);
    }
  }, [animate]);
  const { ref: sliderRef, inView: isSliderInView } = useInView({
    threshold: 0.5,
  });
  const { ref: whatIsVisRef, inView: isWhatIsVisInView } = useInView({
    threshold: 0.5,
  });
  const { ref: whatWeDoRef, inView: isWhatWeDoInView } = useInView({
    threshold: 0.5,
  });
  // const { ref: whyVisRef, inView: isWhyVisView } = useInView({ threshold: 0.5 });
  const { ref: visFocusRef, inView: isVisFocusView } = useInView({
    threshold: 0.5,
  });
  const [whyVisHasAnimated, setWhyVisHasAnimated] = useState(false);
  const [visFocusHasAnimated, setVisFocusHasAnimated] = useState(false);
  const navigate = useNavigate();
  const [servicesBoxAnimated, setServicesBoxAnimated] = useState([]);
  const [startAnimation, setStartAnimation] = useState(false);
  const [isGetInTouch, setIsGetInTouch] = useState(false);
  const [nickName, setNickName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [nickNameError, setNickNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const handleGetInTouchButtonClick = () => {
    setIsGetInTouch(true);
    setNickName("");
    setNickNameError("");
    setPhoneNo("");
    setPhoneError("");
    setMessage("");
    setMessageError("");
  };

  const handleCloseGetInTouchPopUp = () => {
    setIsGetInTouch(false);
  };

  const handleNickNameChange = (event) => {
    setNickName(event.target.value);
    setNickNameError("");
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
    setPhoneError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setMessageError("");
  };

  const handleSubmitButtonClick = async (event) => {
    event.preventDefault();
    setNickNameError("");
    setPhoneError("");
    setMessageError("");
    let isValid = true;

    if (!nickName) {
      setNickNameError("Nick name is required");
      isValid = false;
    }
    const phoneRegex = /^\d{10,12}$/;
    if (!phoneNo) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else if (!phoneRegex.test(phoneNo)) {
      setPhoneError("Phone number must be 10 to 12 digits");
      isValid = false;
    }

    if (!message) {
      setMessageError("Message is required");
      isValid = false;
    }

    if (isValid) {
      try {
        const form = document.getElementById("contact-form");
        const result = await emailjs.sendForm(
          "service_2e8327t",
          "template_bzt7nlf",
          form,
          "S5JRYws8ySUwRvLBZ",
        );

        if (result.status === 200) {
          setNickName("");
          setNickNameError("");
          setPhoneNo("");
          setPhoneError("");
          setMessage("");
          setMessageError("");
          setIsSuccessMessage(true);
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
      }
    }
  };

  useEffect(() => {
    if (isSuccessMessage) {
      const timer = setTimeout(() => {
        setIsSuccessMessage(false);
      }, 8000); // 30 seconds
      return () => clearTimeout(timer);
    }
  }, [isSuccessMessage]);

  const handleKeyDownPhone = (event) => {
    const key = event.key;
    if (
      !/^\d$/.test(key) &&
      key !== "Backspace" &&
      key !== "Delete" &&
      key !== "ArrowLeft" &&
      key !== "ArrowRight"
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    let animationTriggered = false; // Flag to prevent repeated animation

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationTriggered) {
            setStartAnimation(true); // Start animation when the section becomes visible
            animationTriggered = true; // Set the flag to prevent future triggers
          }
        });
      },
      { threshold: 0.5 }, // Animation triggers when 50% of the section is visible
    );
    if (boxesRef.current[0]) {
      observer.observe(boxesRef.current[0]); // Observe the first box (or the section container)
    }

    return () => {
      if (boxesRef.current[0]) {
        observer.unobserve(boxesRef.current[0]); // Cleanup observer
      }
    };
  }, []);

  useEffect(() => {
    if (startAnimation) {
      // Animate boxes in sequence with a delay
      boxesRef.current.forEach((box, index) => {
        setTimeout(() => {
          setServicesBoxAnimated((prev) => {
            const updated = [...prev];
            updated[index] = true; // Animate each box in sequence
            return updated;
          });
        }, index * 200); // Delay of 200ms between each box
      });
    } else {
      // Reset animation state when section leaves the viewport
      setServicesBoxAnimated(Array(8).fill(false)); // Ensure the array length matches the number of boxes
    }
  }, [startAnimation]);

  const handleBoxClick = (id) => {
    navigate(`/services?focus=${id}`);
  };
  const handleServicesClick = () => {
    navigate(`/services`);
  };
  const handleAboutUsClick = () => {
    navigate(`/about-us`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const focusLabel = params.get("focus");
    if (focusLabel) {
      const element = document.getElementById(focusLabel);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Grid2
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Background Box with image */}
      <Box
        sx={{
          width: "100%",
          height: { md: "97vh", xs: "97vh" }, // 97% of the viewport height
          position: "relative", // Allows absolute positioning inside
          backgroundImage: `url(${slider})`, // Using the imported image
          backgroundSize: "cover", // Ensures the image covers the entire box
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Header is positioned absolutely within the background box */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 2,
            animation: "slideDown 1s ease-out forwards",
            "@keyframes slideDown": {
              "0%": { transform: "translateY(-100%)" }, // Start off-screen (above)
              "100%": { transform: "translateY(0)" }, // End at its normal position
            },
          }}
        >
          <Header currentPage={"Home"} sx={{ width: "100%" }} />
        </Box>

        {/* Content inside the box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "92%",
            maxWidth: "90%",
            paddingTop: "3%",
            paddingBottom: { xs: "25%", md: "4%" },
            gap: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: { xs: "0%", md: "51%" },
              height: "100%",
            }}
          ></Box>
          <SliderContent
            handleGetInTouchButtonClick={handleGetInTouchButtonClick}
            handleServicesClick={handleServicesClick}
          />
        </Box>
      </Box>

      {/* Below the background box */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "92%",
          paddingTop: { xs: "4%", md: "4%" },
          paddingBottom: { xs: "4%", md: "2%" },
          gap: { xs: "24px", md: "68px" },
        }}
      >
        <WhatIsVISComponent
          imageSrc={home1}
          handleAboutUsClick={handleAboutUsClick}
        />
        <WhatWeDo imageSrc={home2} handleServicesClick={handleServicesClick} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "96%",
          maxWidth: "92%",
          paddingTop: "4%",
          paddingBottom: "2%",
          gap: "16px",
          alignItems: { md: "center", xs: "flex-start" },
        }}
      >
        <TypographyHeading
          sx={{
            alignItems: "center",
            paddingBottom: "2%",
          }}
        >
          Our services
        </TypographyHeading>
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            flexWrap: "wrap",
            gap: { xs: "12px", md: "48px" },
          }}
        >
          {[
            {
              id: "web",
              label: "Website Development",
              mobileImage: homeicon1mob,
              desktopImage: homeicon1,
              content:
                "Web development is the process of building and maintaining websites that are dynamic, user-friendly, and tailored to meet specific business needs. It encompasses both the design and",
            },
            {
              id: "app",
              label: "App Development",
              mobileImage: homeicon2mob,
              desktopImage: homeicon2,
              content:
                "App development involves the creation of mobile and web applications that provide seamless user experiences across various platforms. By combining innovative design with cutting-edge technology, app",
            },
            {
              id: "software",
              label: "Software Development",
              mobileImage: homeicon3mob,
              desktopImage: homeicon3,
              content:
                "Software development is the process of designing, coding, testing, and maintaining software solutions that meet specific business or user needs. It involves creating custom applications,",
            },
            {
              id: "uiDesign",
              label: "UI-UX Design",
              mobileImage: homeicon4,
              desktopImage: homeicon4,
              content:
                "UI / UX design is essential for creating digital experiences that are not only visually appealing but also intuitive and user-friendly. UI (User Interface) design focuses on the layout and",
            },
            {
              id: "uiAudit",
              label: "UI-UX Audit",
              mobileImage: homeicon5mob,
              desktopImage: homeicon5,
              content: `A UI/UX audit is a comprehensive evaluation of a website or app's design and functionality, aimed at identifying areas that can be improved to enhance user experience. During the audit, usability experts`,
            },
            {
              id: "digital",
              label: "Digital marketing",
              mobileImage: homeicon6mob,
              desktopImage: homeicon6,
              content:
                "Digital marketing is an essential component for driving online success and growth in today’s competitive landscape. By leveraging strategies like search engine optimization (SEO), pay-per-click",
            },
            {
              id: "automation",
              label: "Automation",
              mobileImage: homeicon7mob,
              desktopImage: homeicon7,
              content:
                "Automation is transforming the way businesses operate by streamlining processes, reducing manual tasks, and increasing efficiency. In web development and beyond, automation allows",
            },
            {
              id: "qa",
              label: "QA-Testing",
              mobileImage: homeicon8mob,
              desktopImage: homeicon8,
              content:
                "QA (Quality Assurance) testing is a vital step in the web development process, ensuring that websites and applications function flawlessly before they are launched. Through thorough testing,",
            },
            {
              id: "cyber",
              label: "Cyber Security",
              mobileImage: homeicon9mob,
              desktopImage: homeicon9,
              content:
                "Cybersecurity is the practice of protecting systems, networks, and data from digital attacks, unauthorized access, and damage. It encompasses a range of measures to safeguard",
            },
          ]
            .filter((item) => item.label)
            .map((item, index) => (
              <Box
                key={index}
                ref={(el) => (boxesRef.current[index] = el)} // Assign each box a ref
                className={
                  servicesBoxAnimated[index] ? "animate-service-box" : ""
                }
                sx={{
                  display: "flex",
                  flexDirection: "column", // Stack the image and text vertically
                  alignItems: "flex-start",
                  flexBasis: {
                    xs: "calc(33.33% - 8px)",
                    md: "calc(33.33% - 58px)",
                  }, // 3 items per row
                  width: "100%",
                  height: { xs: "170px", md: "224px" },
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  border: "1px solid #cbb8b8",
                  gap: { xs: "8px", md: "8px" },
                  background: "#f9f9f9",
                  padding: { md: "26px", xs: "20px" },
                  overflow: "hidden",
                  opacity: servicesBoxAnimated[index] ? 1 : 0,
                  transform: servicesBoxAnimated[index]
                    ? "translateY(0)"
                    : "translateY(50px)",
                  transition: `transform 0.6s ease ${
                    index * 0.2
                  }s, opacity 0.6s ease ${index * 0.2}s`, // Stagger the animation with a delay
                  "&:hover": {
                    border: "1px solid #D40033", // Red border on hover
                    cursor: "pointer", // Pointer cursor on hover
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
                >
                  {/* Add an image above the label */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: { md: "12px", xs: "10px" },
                      alignItems: "flex-start",
                    }}
                  >
                    <img
                      src={isDesktop ? item.desktopImage : item.mobileImage}
                      alt={item.label}
                      style={{
                        width: { xs: "24px", md: "28px" },
                        height: { xs: "24px", md: "28px" },
                      }}
                    />
                    <TypographyComponent
                      className="TypographyComponent"
                      sx={{
                        fontSize: { xs: "16px", md: "20px" },
                        textAlign: "center",
                        fontWeight: 600,
                        lineHeight: { xs: "20px", md: "26px" },
                        color: Colors.black,
                      }}
                    >
                      {item.label}
                    </TypographyComponent>
                  </Box>
                  <Box sx={{ flexGrow: 1, width: "100%" }}>
                    <TypographyComponent
                      sx={{
                        fontSize: {
                          xs: "12px",
                          md: "14px",
                          overflow: "hidden", // Hide overflowing content
                          textOverflow: "ellipsis", // Add ellipsis at the end
                          display: "-webkit-box", // Display as a box for webkit-based browsers
                          WebkitBoxOrient: "vertical", // Vertical layout for the box
                          WebkitLineClamp: 3, // Limit to 3 lines
                          height: { xs: "48px", md: "60px" }, // Adjust height for visible lines
                          wordWrap: "break-word",
                        },
                        lineHeight: "28px",
                      }}
                    >
                      {item.content}
                    </TypographyComponent>
                  </Box>
                </Box>
                <Button
                  onClick={() => handleBoxClick(item.id)} // Handle click event
                  sx={{
                    color: Colors.primary,
                    textDecoration: "none",
                    boxShadow: "none",
                    fontSize: "16px",
                    fontFamily: "IBM Plex Sans",
                    textTransform: "capitalize",
                    padding: "0px",
                    "&:hover": {
                      background: "none",
                      textTransform: "none",
                      boxShadow: "none",
                    },
                  }} // Same styles as the Link
                >
                  Read More
                </Button>
              </Box>
            ))}
        </Box>
      </Box>

      <QuoteBox
        boxRef={boxRef}
        animate={animate}
        shake={shake}
        showContentQuote={showContentQuote}
        handleGetInTouchButtonClick={handleGetInTouchButtonClick}
      />
      <Box
        sx={{
          display: "flex",
          backgroundImage: { xs: "none", md: `url(${vis1})` },
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "92%", xs: "100%" },
            paddingTop: { xs: "4%", md: "2%" },
            paddingBottom: { xs: "4%", md: "2%" },
            gap: { xs: "24px", md: "38px" },
          }}
        >
          <WhyChooseVis imageSrc={home3} />
          <VisFocus imageSrc={home4} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${vis2})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 24px)",
            maxWidth: "92%",
            height: "100%",
            justifyContent: "left",
            alignItems: "left",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "12px",
          }}
        >
          <TypographyHeading
            sx={{
              textAlign: "center",
            }}
          >
            Clients review
          </TypographyHeading>
          {/* <TypographyComponent>
                    At Helping Flocks, we believe that our success is measured by the success of our clients. Hear from some of the incredible brands we've had the pleasure of working with. Their feedback is a testament to our dedication, creativity, and results-driven approach.
                </TypographyComponent> */}
          <ReviewSlider></ReviewSlider>
        </Box>
      </Box>

      {isGetInTouch && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "200",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: { md: "35%", sm: "35%", xs: "85%" },
                height: "36px",
                justifyContent: "right",
                alignItems: "right",
                display: "flex",
              }}
            >
              <IconButton
                onClick={handleCloseGetInTouchPopUp}
                sx={{
                  position: "fixed",
                  width: "36px",
                  height: "36px",
                }}
              >
                <CloseOutlinedIcon
                  sx={{
                    width: "36px",
                    height: "36px",
                    color: Colors.primary,
                    "&:hover": {
                      width: "40px",
                      height: "40px",
                      color: Colors.Red,
                      backgroundColor: "none",
                      background: "none",
                    },
                  }}
                ></CloseOutlinedIcon>
              </IconButton>
            </Box>
            <Box
              sx={{
                width: { md: "35%", sm: "35%", xs: "85%" },
                height: "fit-content",
                backgroundColor: Colors.white,
                border: "1px solid",
                borderColor: Colors.white,
                borderRadius: "4px ",
                boxShadow: "none",
                boxSizing: "border-box",
                zIndex: "3",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "16px",
                    paddingTop: "7%",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "86%",
                    maxWidth: "86%",
                    paddingBottom: "7%",
                  }}
                >
                  {!isSuccessMessage && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "20px",
                        justifyContent: "center",
                        height: "100%",
                        alignItems: "left",
                      }}
                    >
                      <TypographyComponent
                        sx={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        Get in touch with us
                      </TypographyComponent>
                      <form
                        onSubmit={handleSubmitButtonClick}
                        id="contact-form"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "stretch",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              gap: "4px",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <TypographyComponent
                              sx={{
                                fontSize: "16px",
                                color: Colors.black_80,
                                fontWeight: 400,
                              }}
                            >
                              Nick name
                            </TypographyComponent>
                            <TextfieldComponent
                              variant="outlined"
                              value={nickName}
                              name="nickName"
                              placeholder="Enter your nick name"
                              error={!!nickNameError}
                              onChange={handleNickNameChange}
                            />
                            {nickNameError && (
                              <TypographyComponent sx={{ color: Colors.Red }}>
                                {nickNameError}
                              </TypographyComponent>
                            )}
                          </Box>

                          <Box
                            sx={{
                              gap: "4px",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <TypographyComponent
                              sx={{
                                fontSize: "16px",
                                color: Colors.black_80,
                                fontWeight: 400,
                              }}
                            >
                              Phone number
                            </TypographyComponent>
                            <TextfieldComponent
                              variant="outlined"
                              value={phoneNo}
                              name="phoneNo"
                              placeholder="Enter your phone number"
                              onChange={handlePhoneNoChange}
                              onKeyDown={handleKeyDownPhone}
                              inputProps={{ maxLength: 12 }}
                              error={!!phoneError}
                            />
                            {phoneError && (
                              <TypographyComponent sx={{ color: Colors.Red }}>
                                {phoneError}
                              </TypographyComponent>
                            )}
                          </Box>

                          <Box
                            sx={{
                              gap: "4px",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <TypographyComponent
                              sx={{
                                fontSize: "16px",
                                color: Colors.black_80,
                                fontWeight: 400,
                              }}
                            >
                              Message
                            </TypographyComponent>
                            <TextField
                              variant="outlined"
                              multiline
                              rows={3}
                              placeholder="Type your queries"
                              value={message}
                              name="message"
                              onChange={handleMessageChange}
                              error={!!messageError}
                              sx={{
                                height: "84px",
                                width: "100%",
                                padding: 0,
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "1px solid #c5c5c5",
                                    borderColor: messageError
                                      ? Colors.Red
                                      : Colors.black_60,
                                    transition: "border-color 0.3s ease",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: messageError
                                      ? Colors.Red
                                      : Colors.black_80,
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: messageError
                                      ? Colors.Red
                                      : Colors.black_80,
                                  },
                                  "& .MuiInputBase-input::placeholder": {
                                    lineHeight: "18px",
                                    fontFamily: "IBM Plex Sans ",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: Colors.black_70,
                                  },
                                  "& .MuiInputBase-input": {
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    fontFamily: "IBM Plex Sans ",
                                    fontWeight: 400,
                                    color: Colors.black_80,
                                  },
                                },
                              }}
                            />
                            {messageError && (
                              <TypographyComponent sx={{ color: Colors.Red }}>
                                {messageError}
                              </TypographyComponent>
                            )}
                          </Box>

                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              paddingTop: "8px",
                              justifyContent: "right",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <ButtonRed
                              type="submit"
                              sx={{ width: "40%", gap: "4px" }}
                            >
                              Submit
                            </ButtonRed>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                  )}

                  {isSuccessMessage && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "420px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: { xs: "180px", md: "74px", sm: "180px" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "16px",
                          borderRadius: "4px",
                        }}
                      >
                        <CheckCircleIcon
                          style={{
                            color: "#0BEA14",
                            borderRadius: "50%",
                            fontSize: "48px",
                          }}
                        />
                        <TypographyComponent
                          sx={{
                            fontWeight: 400,
                            fontSize: "18px",
                            color: Colors.black_70,
                            lineHeight: "28px",
                            textAlign: "center",
                          }}
                        >
                          Your message has been sent successfully. We appreciate
                          you reaching out to us. Our team will get back to you
                          as soon as possible.
                        </TypographyComponent>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Footer currentPage={"Home"}></Footer>
    </Grid2>
  );
};

export default HomeNew;
