import React, { useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Grid2,
} from "@mui/material";
import Header from "../components/Header";
// import headerbg from '../Images/headerbg.png';
// import bgmob from '../Images/bgmob.jpg';
import Colors from "../components/Colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Footer from "../components/Footer";
import p1 from "../images/p1.png";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
import p4 from "../images/p4.png";
// import s6 from '../images/s6.png'

import p1mob from "../images/p1mob.png";
import p2mob from "../images/p2mob.png";
import p3mob from "../images/p3mob.png";
import p4mob from "../images/p4mob.png";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TypographyComponent from "../components/TypographyComponent";
import { useMediaQuery } from "@mui/material";
import TypographyHeading from "../components/TypographyHeader";
import { useInView } from "react-intersection-observer";
import ButtonRed from "../components/ButtonRed";
import SvgComponent from "../components/SvgComponent";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:768px)");

  // const { ref: webRef, inView: isWebInView } = useInView({ threshold: 0.5 });
  const broRef = useRef(null);
  const helpRef = useRef(null);
  const rateRef = useRef(null);
  const divineRef = useRef(null);
  const [broIsInView, setBroIsInView] = useState(false);
  const [helpIsInView, setHelpIsInView] = useState(false);
  const [rateIsInView, setRateIsInView] = useState(false);
  const [divineIsInView, setDivineIsInView] = useState(false);
  // const { ref: broRef, inView: isBroInView } = useInView({ threshold: 0.5 });
  // const { ref: helpRef, inView: isHelpInView } = useInView({ threshold: 0.5 });
  // const { ref: rateRef, inView: isRateInView } = useInView({ threshold: 0.5 });
  // const { ref: divineRef, inView: isDivineInView } = useInView({ threshold: 0.5 });

  // const [broHasAnimated, setBroHasAnimated] = useState(false);
  // const [helpHasAnimated, setHelpHasAnimated] = useState(false);
  //  const [rateHasAnimated, setRateHasAnimated] = useState(false);
  //  const [divineHasAnimated, setDivineHasAnimated] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === broRef.current) {
              setBroIsInView(true);
            } else if (entry.target === helpRef.current) {
              setHelpIsInView(true);
            } else if (entry.target === rateRef.current) {
              setRateIsInView(true);
            } else if (entry.target === divineRef.current) {
              setDivineIsInView(true);
            }
            observer.unobserve(entry.target); // Stop observing once in view
          }
        });
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    // Add observers for each reference
    if (broRef.current) observer.observe(broRef.current);
    if (helpRef.current) observer.observe(helpRef.current);
    if (rateRef.current) observer.observe(rateRef.current);
    if (divineRef.current) observer.observe(divineRef.current);

    // Clean up function to unobserve each reference
    return () => {
      if (broRef.current) observer.unobserve(broRef.current);
      if (helpRef.current) observer.unobserve(helpRef.current);
      if (rateRef.current) observer.unobserve(rateRef.current);
      if (divineRef.current) observer.unobserve(divineRef.current);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const focusLabel = params.get("focus");
    if (focusLabel) {
      const element = document.getElementById(focusLabel);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Grid2
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          animation: "slideDown 1s ease-out forwards",
          "@keyframes slideDown": {
            "0%": { transform: "translateY(-100%)" }, // Start off-screen (above)
            "100%": { transform: "translateY(0)" }, // End at its normal position
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <Header
          currentPage={"Products"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 2,
          }}
        />
      </Box>

      <Box
        sx={{
          height: "95%",
          display: "flex",
          width: "100%",
          maxWidth: "90%",
          gap: { sm: "14px", md: "28px", xs: "14px" },
          paddingTop: { sm: "2%", xs: "2%", md: "3%" },
          paddingBottom: { xs: "1%", md: "4%" },
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          ref={broRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: "12px", md: "24px", xs: "16px" },
            opacity: broIsInView ? 1 : 0, // Fade in when in view
            transform: broIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TypographyHeading
              sx={{
                fontSize: { xs: "20px", md: "42px" },
                lineHeight: { md: "84px", xs: "40px" },
                mb: "8px",
              }}
            >
              Our Products
            </TypographyHeading>
          </Box>
          <TypographyHeading>Brohandy</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "100%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              id="web"
              sx={{
                width: { xs: "97%", md: "50%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },

                // animation: broHasAnimated ? 'slideInLeft 1s ease-out' : 'none',  // Slide from the left
                // '@keyframes slideInLeft': {
                //     '0%': { transform: 'translateX(-100%)', opacity: 0 }, // Start off-screen to the left
                //     '100%': { transform: 'translateX(0)', opacity: 1 },  // End in normal position
                // }
              }}
            >
              <SvgComponent
                desktopSrc={p1}
                mobileSrc={p1mob}
                alt="Image 1"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
                // animation: broHasAnimated ? 'slideInRight 1s ease-out' : 'none', // Slide from the right
                // '@keyframes slideInRight': {
                //     '0%': { transform: 'translateX(100%)', opacity: 0 },  // Start off-screen to the right
                //     '100%': { transform: 'translateX(0)', opacity: 1 },   // End in normal position
                // }
              }}
            >
              <TypographyComponent>
                More than just a digital marketing agency, Helping Flocks is a
                collective of passionate innovators, strategists, and creators
                dedicated to elevating brands in the digital space. The team is
                driven by a mission to help businesses of all sizes find their
                voice, reach their audience, and achieve their full potential
                online, believing in the power of connection, creativity, and
                data-driven decisions. With diverse expertise spanning various
                industries, Helping Flocks tailors innovative solutions to meet
                each client's unique needs. Comprising strategists, designers,
                analysts, and content creators, the team crafts impactful
                campaigns that resonate with audiences, ensuring every marketing
                decision aligns with the brand’s vision. By prioritizing strong,
                lasting relationships and viewing themselves as an extension of
                your team, Helping Flocks is a dedicated partner committed to
                helping your brand soar to new heights.
              </TypographyComponent>
              <ButtonRed
                sx={{
                  maxWidth: "180px",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "20px",
                }}
              >
                <a
                  href="https://brohandy.com/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  www.brohandy.com
                </a>
              </ButtonRed>
            </Box>
          </Box>
        </Box>
        <Box
          ref={helpRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: "12px", md: "24px", xs: "16px" },
            opacity: helpIsInView ? 1 : 0, // Fade in when in view
            transform: helpIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          }}
        >
          <TypographyHeading>Helping flocks</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "100%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 1 },
                width: { xs: "97%", md: "44%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                More than just a digital marketing agency, Helping Flocks is a
                collective of passionate innovators, strategists, and creators
                dedicated to elevating brands in the digital space. The team is
                driven by a mission to help businesses of all sizes find their
                voice, reach their audience, and achieve their full potential
                online, believing in the power of connection, creativity, and
                data-driven decisions. With diverse expertise spanning various
                industries, Helping Flocks tailors innovative solutions to meet
                each client's unique needs. Comprising strategists, designers,
                analysts, and content creators, the team crafts impactful
                campaigns that resonate with audiences, ensuring every marketing
                decision aligns with the brand’s vision. By prioritizing strong,
                lasting relationships and viewing themselves as an extension of
                your team, Helping Flocks is a dedicated partner committed to
                helping your brand soar to new heights.
              </TypographyComponent>
              <ButtonRed
                sx={{
                  maxWidth: "180px",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "20px",
                }}
              >
                {" "}
                <ButtonRed
                  sx={{
                    maxWidth: "180px",
                    fontWeight: 600,
                    fontSize: "10px",
                    lineHeight: "20px",
                  }}
                >
                  <a
                    href="https://helpingflock.com/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    www.helpingflock.com
                  </a>
                </ButtonRed>
              </ButtonRed>
            </Box>
            <Box
              id="app"
              sx={{
                order: { xs: 1, md: 2 },
                width: { xs: "97%", md: "50%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={p2}
                mobileSrc={p2mob}
                alt="Image 2"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          ref={rateRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: "12px", md: "24px", xs: "16px" },
            opacity: rateIsInView ? 1 : 0, // Fade in when in view
            transform: rateIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          }}
        >
          <TypographyHeading>Ratewaves</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              id="software"
              sx={{
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={p3}
                mobileSrc={p3mob}
                alt="Image 3"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "97%", md: "44%" },
                alignItems: "flex-start",
                padding: "3%",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                More than just a digital marketing agency, Helping Flocks is a
                collective of passionate innovators, strategists, and creators
                dedicated to elevating brands in the digital space. The team is
                driven by a mission to help businesses of all sizes find their
                voice, reach their audience, and achieve their full potential
                online, believing in the power of connection, creativity, and
                data-driven decisions. With diverse expertise spanning various
                industries, Helping Flocks tailors innovative solutions to meet
                each client's unique needs. Comprising strategists, designers,
                analysts, and content creators, the team crafts impactful
                campaigns that resonate with audiences, ensuring every marketing
                decision aligns with the brand’s vision. By prioritizing strong,
                lasting relationships and viewing themselves as an extension of
                your team, Helping Flocks is a dedicated partner committed to
                helping your brand soar to new heights.
              </TypographyComponent>
              <ButtonRed
                sx={{
                  maxWidth: "180px",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "20px",
                }}
              >
                {" "}
                <ButtonRed
                  sx={{
                    maxWidth: "180px",
                    fontWeight: 600,
                    fontSize: "10px",
                    lineHeight: "20px",
                  }}
                >
                  <a
                    href="https://ratewaves.com/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    www.ratewaves.com
                  </a>
                </ButtonRed>
              </ButtonRed>
            </Box>
          </Box>
        </Box>
        <Box
          ref={divineRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: "12px", md: "24px", xs: "16px" },
            opacity: divineIsInView ? 1 : 0, // Fade in when in view
            transform: divineIsInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
            transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          }}
        >
          <TypographyHeading>Divine</TypographyHeading>
          <Box
            sx={{
              display: "flex",
              width: { md: "98%" },
              height: "98%",
              flexDirection: { md: "row", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",
              padding: { md: "2%" },
              "@media (min-width: 900px)": {
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                },
                // Top-left border
                "&::before": {
                  top: 0,
                  left: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderTop: "0.5px solid #D40033",
                  borderLeft: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
                // Bottom-right border
                "&::after": {
                  bottom: 0,
                  right: 0,
                  width: "158px", // Adjust length as needed
                  height: "158px",
                  borderBottom: "0.5px solid #D40033",
                  borderRight: "0.5px solid #D40033",
                  boxSizing: "border-box",
                },
              },
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 1 },
                width: { xs: "97%", md: "44%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "14px", md: "24px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                More than just a digital marketing agency, Helping Flocks is a
                collective of passionate innovators, strategists, and creators
                dedicated to elevating brands in the digital space. The team is
                driven by a mission to help businesses of all sizes find their
                voice, reach their audience, and achieve their full potential
                online, believing in the power of connection, creativity, and
                data-driven decisions. With diverse expertise spanning various
                industries, Helping Flocks tailors innovative solutions to meet
                each client's unique needs. Comprising strategists, designers,
                analysts, and content creators, the team crafts impactful
                campaigns that resonate with audiences, ensuring every marketing
                decision aligns with the brand’s vision. By prioritizing strong,
                lasting relationships and viewing themselves as an extension of
                your team, Helping Flocks is a dedicated partner committed to
                helping your brand soar to new heights.
              </TypographyComponent>
              <ButtonRed
                sx={{
                  maxWidth: "180px",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "20px",
                }}
              >
                {" "}
                <ButtonRed
                  sx={{
                    maxWidth: "180px",
                    fontWeight: 600,
                    fontSize: "10px",
                    lineHeight: "20px",
                  }}
                >
                  www.divine.com
                </ButtonRed>
              </ButtonRed>
            </Box>
            <Box
              id="ui-ux-design"
              sx={{
                order: { xs: 1, md: 2 },
                width: { xs: "97%", md: "44%" },
                height: "100%",
                padding: "3%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "@media (max-width: 600px)": {
                  "&::before, &::after": {
                    content: '""',
                    position: "absolute",
                  },
                  // Top-left border
                  "&::before": {
                    top: 0,
                    left: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderTop: "0.5px solid #D40033",
                    borderLeft: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                  // Bottom-right border
                  "&::after": {
                    bottom: 0,
                    right: 0,
                    width: "92px", // Adjust length as needed
                    height: "82px",
                    borderBottom: "0.5px solid #D40033",
                    borderRight: "0.5px solid #D40033",
                    boxSizing: "border-box",
                  },
                },
              }}
            >
              <SvgComponent
                desktopSrc={p4}
                mobileSrc={p4mob}
                alt="Image 4"
                style={{
                  width: "100%",
                  maxWidth: { xs: "97%", md: "100%" },
                  height: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer currentPage={"Products"}></Footer>
    </Grid2>
  );
};

export default Products;
