import React, { useState } from "react";
import { Box } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { keyframes } from "@emotion/react"; // Or use plain CSS
import ButtonTransparent from "./ButtonTransparent"; // Replace with actual imports
import ButtonRed from "./ButtonRed";
import TypographySliderHeading from "./TypographySliderHeader";
import TypographySliderComponent from "./TypographySliderComponent";
import Colors from "../components/Colors";

// Animation keyframe for sliding from right to left
const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50%); // Start from the right
  }
  100% {
    opacity: 1;
    transform: translateX(0); // Move to the original position
  }
`;

const SliderContent = ({
  handleGetInTouchButtonClick,
  handleServicesClick,
}) => {
  const [hasAnimated, setHasAnimated] = useState(false);

  // Using react-intersection-observer to trigger animation when in view
  const { ref: boxRef, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.2, // Trigger when 20% of the box is in view
  });

  // Trigger the animation when the box comes into view
  if (inView && !hasAnimated) {
    setHasAnimated(true);
  }

  return (
    <Box
      ref={boxRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", md: "49%" },
        height: "100%",
        justifyContent: { md: "center", xs: "flex-end" },
        alignItems: "flex-start",
        gap: { md: "24px", xs: "12px" },
        opacity: 0, // Initially hidden
        animation: hasAnimated
          ? `${slideInFromRight} 1s ease forwards` // Slide from right to left
          : "none",
        animationFillMode: "forwards",
      }}
    >
      <TypographySliderHeading
        sx={{ textAlign: "left", color: Colors.black_80 }}
      >
        Making your ideas happen!
      </TypographySliderHeading>
      <TypographySliderComponent
        sx={{ textAlign: "left", color: Colors.black_80 }}
      >
        We turn your vision into reality with innovative solutions and strategic
        execution. From concept to completion, our team is dedicated to
        delivering high-quality results that align with your goals. Whether it's
        digital marketing, web development, or creative design, we’re here to
        make it happen—just the way you imagined.
      </TypographySliderComponent>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: { md: "24px", xs: "12px" },
          justifyContent: "flex-start",
        }}
      >
        <ButtonTransparent
          onClick={handleGetInTouchButtonClick}
          sx={{ maxWidth: { xs: "92px", md: "196px" }, color: Colors.black }}
        >
          Get started
        </ButtonTransparent>
        <ButtonRed
          onClick={handleServicesClick}
          sx={{ maxWidth: { xs: "92px", md: "196px" } }}
        >
          Our services
        </ButtonRed>
      </Box>
    </Box>
  );
};

export default SliderContent;
