import React from "react";

const SvgComponent = ({ desktopSrc, mobileSrc, alt = "", style = {} }) => (
  <img
    src={mobileSrc} // Mobile as default
    srcSet={`${mobileSrc} 600w, ${desktopSrc} 768w`} // Define breakpoints
    sizes="(max-width: 767px) 600px, 768px"
    alt={alt}
    style={style}
    loading="lazy" // Lazy-load for better performance
  />
);

export default SvgComponent;
