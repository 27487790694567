import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Avatar, Rating, IconButton, Button } from "@mui/material";
import divine from "../images/divinelogo.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TypographyComponent from "./TypographyComponent";
import { useMediaQuery } from "@mui/material";
import lijo from "../images/lijo.svg";
import lijomob from "../images/lijomob.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Colors from "./Colors";
import BalloonAvatar from "./BalloonAvatar";

const carouselOverlayStyles = `
    .carousel-item {
        position: relative;
        width: 100%;
        height: 92vh;
        overflow: hidden;
    }

    .carousel-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color:rgba(45, 45, 45, 0.48);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1;
    }

    @media (max-width: 960px) {
        .carousel-item {
            height: 480px;
        }
    }

    @media (max-width: 600px) {
        .carousel-item {
            height: 620px;
        }
    }

    .carousel-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slick-dots {
        margin-top:75px;
        position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        z-index: 2;
    }

    .slick-dots li {
        margin: 0 5px;
        display: inline-block;
    }
        @media (max-width: 576px) { /* Adjust the max-width value to match your xs breakpoint */
    .slick-dots li {
        margin: 0 0px; /* Reduce this value to decrease spacing on xs screens */
    }
}

    .slick-dots li button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background-color: #132436A1;
        cursor: pointer;
        outline: 'none';
    }
@media (max-width: 767px) {
    .slick-dots li button {
        width: 12px; /* Change this to the desired width for xs screens */
        height: 12px; /* Change this to the desired height for xs screens */
    }
}
    .slick-dots li.slick-active button {
        background-color: #DD223F;
    }

    .slick-dots li button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
    }
`;

const carouselDataReview = [
  {
    image: lijo,
    imagemob: lijomob,
    name: "lijo",
    rating: 4,
    review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
 tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
 veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
 commodo consequat. Duis aute irure dolor in reprehenderit in voluptate v`,
  },
  {
    image: lijo,
    imagemob: lijomob,
    name: "LIJO MATHEW",
    rating: 4,
    review:
      "Helping Flocks isn't just a marketing agency; they're an extension of our team. Their collaborative approach and dedication to our success have made them an invaluable partner. From social media campaigns to email marketing, they've consistently delivered quality work that aligns with our brand values. We trust them implicitly to guide our digital marketing efforts.",
  },
  // { image: lijo, name:'Kiran', rating:4, review:"I've worked with several digital marketing agencies, but none have impressed me as much as Helping Flocks. Their creativity, attention to detail, and commitment to results are unparalleled. They helped us launch a new product line with a campaign that exceeded all our expectations in terms of engagement and sales. We look forward to continuing our successful partnership with them" },
  // { image: lijo, name:'Kiran', rating:4, review:"Helping Flocks has been instrumental in our digital transformation. Their team provided us with exceptional service, from the initial consultation to the execution of our campaigns. They were always available to answer our questions and made the entire process seamless. The results speak for themselves – we’ve seen a significant boost in our online presence and customer engagement." },
  // { image: lijo, name:'Albert', rating:3, review:"We came to Helping Flocks with a complex challenge, and they delivered beyond our expectations. Their team developed creative marketing solutions that not only addressed our immediate needs but also set us up for long-term success. Their expertise in digital strategy is evident, and we’ve seen a marked improvement in our brand visibility and customer acquisition. We highly recommend their services!" },
];

const PrevArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      display: "block",
      position: "absolute",
      top: "50%",
      left: { xs: "-16px", md: "10px" },
      transform: "translateY(-50%)",
      zIndex: 2,
      color: Colors.black_80,
      "&:hover": {
        color: Colors.black_80,
      },
    }}
  >
    <ArrowBackIosIcon sx={{ fontSize: { xs: "20px", md: "36px" } }} />
  </IconButton>
);

const NextArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      display: "block",
      position: "absolute",
      top: "50%",
      right: { xs: "-16px", md: "10px" },
      transform: "translateY(-50%)",
      zIndex: 2,
      color: Colors.black_80,
      "&:hover": {
        color: Colors.black_80,
      },
    }}
  >
    <ArrowForwardIosIcon sx={{ fontSize: { xs: "20px", md: "36px" } }} />
  </IconButton>
);

const ReviewSlider = () => {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    // appendDots: dots => <ul className="slick-dots-review">{dots}</ul>,
    // customPaging: i => <button className="slick-dot-review" />
  };

  return (
    <>
      <style>{carouselOverlayStyles}</style>
      <Box sx={{ position: "relative" }}>
        <Slider {...settings}>
          {carouselDataReview.map((slide, index) => (
            <div key={index}>
              <Box
                className="carouselOverlayStyles"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  // height: {  md: '592px' },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: { xs: "calc(100% - 16px)", md: "calc(100% - 48px)" },
                    height: "fit-content",
                    maxWidth: { xs: "80%", md: "60%" },
                    gap: { xs: "16px", md: "20px" },
                    // backgroundColor: '#FBFBFB',
                    borderRadius: "32px",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingBottom: "14px",
                    padding: { xs: "10px 16px", md: "60px 32px 16px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "52px", md: "200px" },
                      height: { xs: "48px", md: "200px" },
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      transition: "transform 0.10s ease",
                    }}
                  >
                    <BalloonAvatar
                      alt={slide.name}
                      //  src={slide.image}
                      src={isDesktop ? slide.image : slide.imagemob}
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: Colors.primary,
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: Colors.primary,
                      maxWidth: { xs: "240px", md: "574px" },
                      width: {
                        xs: "calc(100% - 52px)",
                        md: "calc(100% - 128px)",
                      },
                      height: "fit-content",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "8px",
                      padding: { xs: "24px", md: "32px" },
                      borderRadius: { xs: "4px", md: "20px" },
                      transition: "max-height 0.3s ease", // Transition for smooth expanding
                      maxHeight: showMore ? "1000px" : "200px", // Adjust maxHeight as per content size
                      overflow: "hidden",
                    }}
                    onClick={toggleShowMore} // Make entire box clickable
                  >
                    <TypographyComponent
                      sx={{
                        color: Colors.white,
                        textAlign: "left",
                        lineHeight: { xs: "16px", md: "32px" },
                        display: "-webkit-box",
                        WebkitLineClamp: showMore ? "unset" : 4, // Remove line clamp when expanded
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: "pointer", // Indicate that text is clickable
                      }}
                    >
                      {slide.review}
                    </TypographyComponent>

                    {/* <Rating
                                            name="readOnly"
                                            readOnly
                                            size={isDesktop ? 'large' : 'small'}
                                            value={parseInt(slide.rating)}
                                            sx={{ color: '#ffc107' }}
                                            emptyIcon={<StarBorderIcon style={{ color: '#ffc107' }} fontSize="inherit" />}
                                        /> */}
                    {/* <TypographyComponent sx={{
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: { xs: '12px', md: '20px' },
                                            fontWeight: 500
                                        }}>
                                            {slide.name}
                                        </TypographyComponent> */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    mb: "30px",
                    display: "flex",
                    flexDirection: "row",
                    width: { xs: "calc(100% - 16px)", md: "calc(100% - 48px)" },
                    height: "fit-content",
                    maxWidth: { xs: "80%", sm: "60%", md: "60%" },
                    // gap: { xs: '16px', md: '20px' },
                    // backgroundColor: '#FBFBFB',
                    borderRadius: "32px",
                    justifyContent: {
                      xs: "flex-start",
                      sm: "center",
                      md: "flex-end",
                    },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: { xs: "80%", md: "200px" },
                      height: { xs: "52px", md: "36px" },
                      justifyContent: {
                        xs: "flex-start",
                        sm: "flex-end",
                        md: "flex-start",
                      },
                      alignItems: "flex-start",
                      gap: "8px",
                      // border:'1px solid red'
                    }}
                  >
                    <Avatar
                      sx={{ width: "36px", height: "36px" }}
                      src={divine}
                    ></Avatar>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <TypographyComponent
                        sx={{
                          fontWeight: 600,
                          fontSize: "12px",
                          lineHeight: "28px",
                          textTransform: "uppercase",
                        }}
                      >
                        {slide.name}
                      </TypographyComponent>
                      <TypographyComponent
                        sx={{
                          fontWeight: 600,
                          fontSize: "8px",
                          lineHeight: "12px",
                          color: "#878787",
                        }}
                      >
                        CEO OF DIVINE CONSTRUCTION & RENOVATION INC.
                      </TypographyComponent>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flexDirection: "column",
                      maxWidth: "574px",
                      width: {
                        xs: "calc(100% - 52px)",
                        md: "calc(100% - 128px)",
                      },
                      maxHeight: "206px",
                      height: {
                        xs: "calc(100% - 16px)",
                        md: "calc(100% - 20px)",
                      },
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "8px",
                      padding: "32px",
                      borderRadius: "20px",
                      display: { md: "flex", xs: "none" },
                    }}
                  ></Box>
                </Box>
              </Box>
            </div>
          ))}
        </Slider>
      </Box>
    </>
  );
};

export default ReviewSlider;
