import React, { useRef, useState } from "react";
import { Box, Grid2, TextField, IconButton } from "@mui/material";
import Header from "../components/Header";
import Colors from "../components/Colors";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TypographyComponent from "../components/TypographyComponent";
import { useMediaQuery } from "@mui/material";
import TypographyHeading from "../components/TypographyHeader";
import { useInView } from "react-intersection-observer";
import emailjs from "emailjs-com";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextfieldComponent from "../components/TextfieldComponent";
import ButtonRed from "../components/ButtonRed";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [nickName, setNickName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [nickNameError, setNickNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing once in view
        }
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    // Trigger animation only on initial load
    setHasAnimated(true);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const focusLabel = params.get("focus");
    if (focusLabel) {
      const element = document.getElementById(focusLabel);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleNickNameChange = (event) => {
    setNickName(event.target.value);
    setNickNameError("");
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
    setPhoneError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setMessageError("");
  };

  const handleSubmitButtonClick = async (event) => {
    event.preventDefault();
    setNickNameError("");
    setPhoneError("");
    setMessageError("");
    let isValid = true;

    if (!nickName) {
      setNickNameError("Nick name is required");
      isValid = false;
    }
    const phoneRegex = /^\d{10,12}$/;
    if (!phoneNo) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else if (!phoneRegex.test(phoneNo)) {
      setPhoneError("Phone number must be 10 to 12 digits");
      isValid = false;
    }

    if (!message) {
      setMessageError("Message is required");
      isValid = false;
    }

    if (isValid) {
      try {
        const form = document.getElementById("contact-form");
        const result = await emailjs.sendForm(
          "service_2e8327t",
          "template_bzt7nlf",
          form,
          "S5JRYws8ySUwRvLBZ",
        );

        if (result.status === 200) {
          setNickName("");
          setNickNameError("");
          setPhoneNo("");
          setPhoneError("");
          setMessage("");
          setMessageError("");
          setIsSuccessMessage(true);
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
      }
    }
  };

  useEffect(() => {
    if (isSuccessMessage) {
      const timer = setTimeout(() => {
        setIsSuccessMessage(false);
      }, 8000); // 30 seconds
      return () => clearTimeout(timer);
    }
  }, [isSuccessMessage]);

  const handleKeyDownPhone = (event) => {
    const key = event.key;
    if (
      !/^\d$/.test(key) &&
      key !== "Backspace" &&
      key !== "Delete" &&
      key !== "ArrowLeft" &&
      key !== "ArrowRight"
    ) {
      event.preventDefault();
    }
  };

  const [isTermPopUp, setIsTermPopUp] = useState(false);

  const handleTermsAndCondtionClick = () => {
    setIsTermPopUp(true);
  };

  const handleCloseTerm = () => {
    setIsTermPopUp(false);
  };

  return (
    <Grid2
      container
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          animation: hasAnimated ? "slideDown 1s ease-out forwards" : "none",
          "@keyframes slideDown": {
            "0%": { transform: "translateY(-100%)" },
            "100%": { transform: "translateY(0)" },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <Header
          currentPage={"Contact us"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 2,
          }}
        />
      </Box>

      <Box
        ref={ref}
        sx={{
          height: "95%",
          display: "flex",
          width: "100%",
          maxWidth: "90%",
          gap: { sm: "14px", md: "28px", xs: "14px" },
          paddingTop: { sm: "2%", xs: "2%", md: "3%" },
          paddingBottom: { xs: "16px", md: "32px" },
          flexDirection: "column",
          alignItems: "center",
          opacity: isInView ? 1 : 0, // Fade in when in view
          transform: isInView ? "translateY(0)" : "translateY(50px)", // Slide up when in view
          transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
        }}
      >
        <TypographyHeading
          sx={{
            fontSize: { xs: "20px", md: "42px" },
            lineHeight: { md: "84px", xs: "40px" },
          }}
        >
          Contact us
        </TypographyHeading>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: "12px", md: "0px", xs: "12px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { md: "100%" },
              height: "98%",
              flexDirection: { md: "column", xs: "column" },
              gap: { md: "42px", xs: "0px" },
              position: "relative",

              "&::before, &::after": {
                content: '""',
                position: "absolute",
              },
              // Top-left border
              "&::before": {
                top: 0,
                left: 0,
                width: "158px", // Adjust length as needed
                height: "158px",
                borderTop: "0.5px solid #D40033",
                borderLeft: "0.5px solid #D40033",
                boxSizing: "border-box",
              },
              // Bottom-right border
              "&::after": {
                bottom: 0,
                right: 0,
                width: "158px", // Adjust length as needed
                height: "158px",
                borderBottom: "0.5px solid #D40033",
                borderRight: "0.5px solid #D40033",
                boxSizing: "border-box",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "94%", md: "94%" },
                padding: "3%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                display: "flex",
                gap: { xs: "16px", md: "0px" },
                flexDirection: "column",
              }}
            >
              <TypographyComponent>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ulla
              </TypographyComponent>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "94%", md: "94%" },
              alignItems: "flex-start",
              justifyContent: "flex-start",
              display: "flex",
              gap: { xs: "12px", md: "8px" },
              flexDirection: "column",
            }}
          >
            <TypographyComponent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                cursor: "pointer",
                color: Colors.blue_6,
                fontSize: { xs: "12px", sm: "16px", md: "16px" },
                fontWeight: 400,
              }}
            >
              <MailIcon
                sx={{
                  color: Colors.primary,
                  fontSize: { sm: "18px", md: "18px", xs: "12px" },
                }}
              />
              <a
                href="mailto:contact@varsha-info.com"
                style={{
                  textDecoration: "none",
                  fontWeight: 400,
                  color: Colors.linkblue,
                  paddingLeft: "4px",
                }}
              >
                contact@<span style={{ fontWeight: 600 }}>varsha-info.com</span>
              </a>
            </TypographyComponent>
            <TypographyComponent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                cursor: "pointer",
                fontSize: { xs: "12px", sm: "16px", md: "16px" },
                fontWeight: 400,
              }}
            >
              <LocationOnIcon
                sx={{
                  color: Colors.primary,
                  fontSize: { sm: "18px", md: "18px", xs: "12px" },
                }}
              />
              60 Queen St E #103,Brampton, ON L6V 1A9,Canada
            </TypographyComponent>
          </Box>
          {!isSuccessMessage && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: "3%",
                flexDirection: "column",
                gap: "8px",
                border: "1px solid",
                borderColor: Colors.black_60,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "95%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  maxWidth: { xs: "90%", md: "94%" },
                  flexDirection: "column",
                  gap: "8px",
                  paddingTop: { xs: "5%", md: "2.5%" },
                  paddingBottom: { xs: "5%", md: "2.5%" },
                }}
              >
                <form
                  onSubmit={handleSubmitButtonClick}
                  id="contact-form"
                  style={{ width: "100%", gap: "16px" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "16px",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { md: "row", xs: "column" },
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "stretch",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          gap: "10px",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          flex: 1,
                        }}
                      >
                        <TypographyComponent
                          sx={{
                            fontSize: "16px",
                            color: Colors.black_80,
                            fontWeight: 400,
                          }}
                        >
                          Nick name
                        </TypographyComponent>
                        <TextfieldComponent
                          variant="outlined"
                          value={nickName}
                          name="nickName"
                          placeholder="Enter your nick name"
                          error={!!nickNameError}
                          onChange={handleNickNameChange}
                        />
                        {nickNameError && (
                          <TypographyComponent sx={{ color: Colors.Red }}>
                            {nickNameError}
                          </TypographyComponent>
                        )}
                      </Box>
                      <Box
                        sx={{
                          gap: "10px",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          flex: 1,
                        }}
                      >
                        <TypographyComponent
                          sx={{
                            fontSize: "16px",
                            color: Colors.black_80,
                            fontWeight: 400,
                          }}
                        >
                          Phone number
                        </TypographyComponent>
                        <TextfieldComponent
                          variant="outlined"
                          value={phoneNo}
                          name="phoneNo"
                          placeholder="Enter your phone number"
                          onChange={handlePhoneNoChange}
                          onKeyDown={handleKeyDownPhone}
                          inputProps={{ maxLength: 12 }}
                          error={!!phoneError}
                        />
                        {phoneError && (
                          <TypographyComponent sx={{ color: Colors.Red }}>
                            {phoneError}
                          </TypographyComponent>
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          gap: "10px",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <TypographyComponent
                          sx={{
                            fontSize: "16px",
                            color: Colors.black_80,
                            fontWeight: 400,
                          }}
                        >
                          Message
                        </TypographyComponent>
                        <TextField
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Type your queries"
                          value={message}
                          name="message"
                          onChange={handleMessageChange}
                          error={!!messageError}
                          sx={{
                            height: "84px",
                            width: "100%",
                            padding: 0,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "1px solid #c5c5c5",
                                borderColor: messageError
                                  ? Colors.Red
                                  : Colors.black_60,
                                transition: "border-color 0.3s ease",
                              },
                              "&:hover fieldset": {
                                borderColor: messageError
                                  ? Colors.Red
                                  : Colors.black_80,
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: messageError
                                  ? Colors.Red
                                  : Colors.black_80,
                              },
                              "& .MuiInputBase-input::placeholder": {
                                lineHeight: "18px",
                                fontFamily: "IBM Plex Sans",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: Colors.black_70,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "14px",
                                lineHeight: "18px",
                                fontFamily: "IBM Plex Sans",
                                fontWeight: 400,
                                color: Colors.black_80,
                              },
                            },
                          }}
                        />
                        {messageError && (
                          <TypographyComponent sx={{ color: Colors.Red }}>
                            {messageError}
                          </TypographyComponent>
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "16px",
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <ButtonRed
                          type="submit"
                          sx={{ width: { xs: "30%", md: "16%" }, gap: "4px" }}
                        >
                          Submit
                        </ButtonRed>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          )}

          {isSuccessMessage && (
            <Box
              sx={{
                width: "100%",
                height: "420px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "row",
                gap: "8px",
                mt: "32px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "180px", md: "74px", sm: "180px" },
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: "32px",
                  alignItems: "center",
                  gap: "12px",
                  background: Colors.white_80,
                  borderRadius: "4px",
                  p: "12px 28px",
                  flexDirection: { md: "row", xs: "column" },
                }}
              >
                <CheckCircleIcon
                  style={{
                    color: "#0BEA14",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                  }}
                />
                <TypographyComponent
                  sx={{
                    fontWeight: 400,
                    fontSize: "18px",
                    color: Colors.black_70,
                  }}
                >
                  Your message has been sent successfully. We appreciate you
                  reaching out to us. Our team will get back to you as soon as
                  possible.
                </TypographyComponent>
              </Box>
            </Box>
          )}

          {isTermPopUp && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "2",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "fixed",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: { md: "35%", sm: "35%", xs: "85%" },
                    height: "36px",
                    justifyContent: "right",
                    alignItems: "right",
                    display: "flex",
                  }}
                >
                  <IconButton
                    //  onClick={handleCloseTerm}
                    sx={{
                      position: "fixed",
                      width: "36px",
                      height: "36px",
                    }}
                  >
                    <CloseOutlinedIcon
                      sx={{
                        width: "36px",
                        height: "36px",
                        color: Colors.primary,
                        "&:hover": {
                          width: "40px",
                          height: "40px",
                          color: Colors.black_60,
                          backgroundColor: "none",
                          background: "none",
                        },
                      }}
                    ></CloseOutlinedIcon>
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    width: { md: "35%", sm: "35%", xs: "85%" },
                    height: "fit-content",
                    backgroundColor: Colors.white,
                    border: "1px solid",
                    borderColor: Colors.white,
                    borderRadius: "4px ",
                    boxShadow: "none",
                    boxSizing: "border-box",
                    zIndex: "3",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "16px",
                        paddingTop: "7%",
                        justifyContent: "center",
                        alignContent: "center",
                        height: "86%",
                        maxWidth: "80%",
                        paddingBottom: "7%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "8px",
                          justifyContent: "center",
                          alignContent: "center",
                          height: "100%",
                          alignItems: "stretch",
                        }}
                      >
                        <TypographyComponent sx={{ textAlign: "left" }}>
                          Terms And Condition
                        </TypographyComponent>

                        <TypographyComponent
                          sx={{ textAlign: "left", paddingTop: "8px" }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </TypographyComponent>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Footer currentPage={"Contact Us"}></Footer>
    </Grid2>
  );
};

export default ContactUs;
