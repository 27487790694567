const Colors = {
  /*** Primary colors ***/
  primary: "#D40033",
  secondary: "#FDE2E2",
  black: "#313131",
  white: "#FFFFFF",

  /*** Hovering colors ***/
  hovering_red: "#B50B34",

  /*** black shades ***/
  black_90: "#201E1E",
  black_80: "#313131",
  black_70: "#616161",
  black_60: "#C5C5C5",

  /*** light shades ***/
  grey_20: "#F9F9F9",
  white_80: "#F6F6F6",
  white: "#FFFFFF",
  linkblue: "#2D96D1",
};

export default Colors;
