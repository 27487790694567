import React from "react";
import { Box } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import TypographyComponent from "./TypographyComponent";
import ButtonRed from "./ButtonRed";
import Colors from "./Colors";

const shakeAnimation = {
  "@keyframes shake": {
    "0%, 100%": { transform: "translateX(0)" },
    "20%, 60%": { transform: "translateX(-10px)" },
    "40%, 80%": { transform: "translateX(10px)" },
  },
  animation: "shake 0.5s ease-in-out",
};

const QuoteBox = ({
  animate,
  shake,
  showContentQuote,
  handleGetInTouchButtonClick,
  boxRef,
}) => {
  return (
    <Box
      ref={boxRef}
      className={animate ? "animate-quote-box" : ""}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: Colors.primary,
        transform: animate ? "translateY(0)" : "translateY(100px)",
        opacity: animate ? 1 : 0,
        transition: "transform 0.6s ease-out, opacity 0.6s ease-out",
        mt: "4%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "92%",
          height: { md: "176px", xs: "82px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showContentQuote && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TypographyComponent
              sx={{
                fontWeight: 500,
                fontSize: { xs: "14px", md: "40px" },
                lineHeight: { xs: "16px", md: "52px" },
                color: Colors.white,
              }}
            >
              Get a free quote today
            </TypographyComponent>
            <ButtonRed
              onClick={handleGetInTouchButtonClick}
              sx={{
                maxWidth: { xs: "124px", md: "196px" },
                width: "100%",
                height: { xs: "32px", md: "48px" },
                borderRadius: "4px",
                gap: { xs: "4px", md: "10px" },
                "&:hover": {
                  background: "#FDE2E2",
                  boxShadow: "none",
                },
                ...(shake ? shakeAnimation : {}),
                padding: "6px 10px",
                background: Colors.white,
              }}
            >
              <SendOutlinedIcon
                sx={{
                  color: Colors.black,
                  width: { xs: "12px", md: "16px" },
                  height: { xs: "12px", md: "16px" },
                }}
              />
              <TypographyComponent
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "12px", md: "18px" },
                  lineHeight: { xs: "16px", md: "24px" },
                  color: Colors.black,
                }}
              >
                Send a request
              </TypographyComponent>
            </ButtonRed>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuoteBox;
