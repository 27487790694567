import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import TypographyComponent from "./TypographyComponent"; // Update path if needed
import TypographyHeading from "./TypographyHeader"; // Update path if needed
import Colors from "./Colors"; // Update path if needed
import bg from "../images/whychoosebg.png";

const WhyChooseVis = ({ imageSrc }) => {
  const [isInView, setIsInView] = useState(false);
  const whyVisRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    if (whyVisRef.current) {
      observer.observe(whyVisRef.current);
    }

    return () => {
      if (whyVisRef.current) {
        observer.unobserve(whyVisRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={whyVisRef}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        gap: { md: "5%", xs: "8px" },
        alignItems: "center",
        justifyContent: "center",
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0)" : "translateY(100px)",
        transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
      }}
    >
      {/* Left Image Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: { md: "50%", xs: "100%" },
          paddingTop: { xs: "4%", md: "2%" },
          paddingBottom: { xs: "4%", md: "2%" },
          height: "94%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: { md: "none", xs: `url(${bg})` },
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "92%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ maxWidth: "460px", width: "100%", height: "100%" }}
            src={imageSrc}
            alt="VIS"
          />
        </Box>
      </Box>

      {/* Right Text Section */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          maxWidth: "92%",
          display: "flex",
          gap: { xs: "14px", md: "24px" },
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <TypographyHeading sx={{ color: Colors.black_80 }}>
          Why choose VIS?
        </TypographyHeading>
        <TypographyComponent
          sx={{ fontWeight: 400, lineHeight: "32px", color: "#1D1A1A" }}
        >
          VIS is an initiative from experienced IT professionals with strong
          expertise in application development and support across various
          domains. VIS excels in application development and production support,
          continuously updating its workforce with new technologies to meet
          customer expectations. By integrating services with customers’
          operations, VIS enhances comfort and productivity. It identifies
          processes that can be streamlined or automated, helping customers
          optimize their workforce for maximum productivity.
        </TypographyComponent>
      </Box>
    </Box>
  );
};

export default WhyChooseVis;
