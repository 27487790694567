import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import TypographyComponent from "./TypographyComponent"; // Update path if needed
import TypographyHeading from "./TypographyHeader"; // Update path if needed
import Colors from "./Colors"; // Update path if needed
import bg from "../images/focusbg.png";

const VisFocus = ({ imageSrc }) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const visFocusRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setHasAnimated(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (visFocusRef.current) {
      observer.observe(visFocusRef.current);
    }

    return () => {
      if (visFocusRef.current) {
        observer.unobserve(visFocusRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={visFocusRef}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        gap: { xs: "8px", md: "5%" },
        alignItems: "center",
        justifyContent: "center",
        animation: hasAnimated ? "slideInBottom 1s ease-out" : "none",
        "@keyframes slideInBottom": {
          "0%": { transform: "translateY(100px)", opacity: 0 },
          "100%": { transform: "translateY(0)", opacity: 1 },
        },
      }}
    >
      {/* Left Text Section */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          maxWidth: "92%",
          display: "flex",
          gap: { xs: "14px", md: "24px" },
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          order: { xs: 2, md: 1 },
        }}
      >
        <TypographyHeading sx={{ color: Colors.black_80 }}>
          VIS focus on following:
        </TypographyHeading>
        <Box sx={{ width: "100%" }}>
          <ul style={{ marginTop: 0 }}>
            <li>
              <TypographyComponent>
                Designing of data warehouse - Understanding vital fact elements
                with surrounding deciding attributes (dimensions) as conceptual
                schema. Converting this conceptual schema to effective seamless
                & reliable RDBMS physical model.
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent>
                Designing Data Integration Process (ETL) from different source
                systems & implementation – This involves ETL operations of
                duplicate removal, reference checking, merging, change capture
                operation, etc.
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent>
                Data Reporting – Understanding what business requires,
                implementing reports by pulling data from the data repository.
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent>
                Data Analysis & trend analysis – With the help of data science
                tools, discover underlying patterns in historical data and
                effectively communicate them to decision/policy makers.
              </TypographyComponent>
            </li>
          </ul>
        </Box>
      </Box>

      {/* Right Image Section */}
      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", md: "50%" },
          paddingTop: "2%",
          paddingBottom: "2%",
          height: "94%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: { md: "none", xs: `url(${bg})` },
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          order: { xs: 1, md: 2 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "92%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <img
            style={{ maxWidth: "512px", width: "100%", height: "100%" }}
            src={imageSrc}
            alt="successImage"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VisFocus;
