import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import TypographyHeading from "./TypographyHeader";
import TypographyComponent from "./TypographyComponent";
import ButtonRed from "./ButtonRed";
import Colors from "./Colors";

const WhatWeDo = ({ imageSrc, handleServicesClick }) => {
  const [isInView, setIsInView] = useState(false);
  const whatWeDoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing once in view
        }
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    if (whatWeDoRef.current) {
      observer.observe(whatWeDoRef.current);
    }

    return () => {
      if (whatWeDoRef.current) {
        observer.unobserve(whatWeDoRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={whatWeDoRef}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        gap: { md: "5%", xs: "8px" },
        alignItems: "center",
        justifyContent: "center",
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0)" : "translateY(50px)",
        transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
      }}
    >
      {/* Right Text Section */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          gap: { xs: "14px", md: "24px" },
          flexDirection: "column",
          order: { xs: 2, md: 1 },
        }}
      >
        <Box
          sx={{
            width: "94px",
            height: "24px",
            backgroundColor: Colors.secondary,
            border: `1px solid ${Colors.primary}`,
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            color: Colors.black,
            fontFamily: "IBM Plex Sans",
          }}
        >
          Our Services
        </Box>
        <TypographyHeading sx={{ color: Colors.black_80 }}>
          What We Do
        </TypographyHeading>
        <TypographyComponent
          sx={{ fontWeight: 400, lineHeight: "32px", color: "#1D1A1A" }}
        >
          VIS is as an initiative from few IT professionals who have strong
          experience in application development & application support from
          different business domain. This makes VIS to excel in application
          development & production support. As IT processes evolves, VIS keep
          its workforce to be updated in new technologies so that it can meet
          customers expectation. Our service being integrated with customers
          business operation so that make customers more comfortable. In an
          organization, VIS identifies major process can be streamlined /
          automated , this make customers to tune their workforce to yield
          maximum productivity.
        </TypographyComponent>
        <ButtonRed
          onClick={handleServicesClick}
          sx={{
            fontWeight: 400,
            maxWidth: { md: "170px", xs: "110px" },
            width: "100%",
            height: { md: "48px", xs: "32px" },
            lineHeight: "16px",
          }}
        >
          Read more
        </ButtonRed>
      </Box>
      {/* Left Image Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: { md: "50%", xs: "100%" },
          paddingTop: { xs: "4%", md: "2%" },
          paddingBottom: { xs: "4%", md: "2%" },
          height: "94%",
          alignItems: "center",
          justifyContent: "center",
          order: { xs: 1, md: 2 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "92%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={imageSrc}
            alt="What We Do"
          />
        </Box>
        {/* Top-left and Bottom-right borders */}
        <Box
          sx={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "25%",
            height: "2px",
            backgroundColor: "#c1c1c1",
          }}
        />
        <Box
          sx={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "2px",
            height: "25%",
            backgroundColor: "#c1c1c1",
          }}
        />
        <Box
          sx={{
            content: '""',
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "25%",
            height: "2px",
            backgroundColor: "#c1c1c1",
          }}
        />
        <Box
          sx={{
            content: '""',
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "2px",
            height: "25%",
            backgroundColor: "#c1c1c1",
          }}
        />
      </Box>
    </Box>
  );
};

export default WhatWeDo;
