import React from "react";
import Button from "@mui/material/Button";
import Colors from "./Colors";

function ButtonRed({ onClick, sx, ...props }) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        boxShadow: "none",
        width: "100%",
        height: { xs: "32px", md: "48px" },
        backgroundColor: Colors.primary,
        borderRadius: "4px",
        color: Colors.white,
        textTransform: "none",
        fontFamily: "IBM Plex Sans",
        padding: { xs: "8px 12px", md: "8px 12px" },
        fontSize: { xs: "12px", md: "16px" },
        whiteSpace: "nowrap",
        fontWeight: "400",
        border: "1px solid ",
        borderColor: Colors.primary,
        "&:hover": {
          background: Colors.hovering_red,
          textTransform: "none",
          color: Colors.white,
          boxShadow: "none",
        },
        ...sx,
      }}
      {...props}
    ></Button>
  );
}
export default ButtonRed;
